import React, { useState, useEffect} from 'react';
import { EncryptData, GetUser, InnerModal, Logout, PostOptions, showAlert } from '../../utils/MojaveLib';
import { useTranslation } from "react-i18next";
import { StaticDialog } from "react-st-modal";
import axios from "axios";

function MyProfile(props){

    const user = GetUser();

    const [passItems, setPassItems] = useState({'id':user.id, 'password':'', 'curpass':'', 'repassword':''});
    const [smShow, setSmShow] = useState(false);

    const {t, i18n} = useTranslation('translation');

    const handleChange = (e) => {
        e.preventDefault();

        const {name, value} = e.target;

        setPassItems(prevProps => ({...prevProps, [name]:value}));
    }

    const showDialog = () =>  setSmShow(true);

    const smClose = () => setSmShow(false);

    const changePassword = () => {

        axios(PostOptions('POST', {'data':EncryptData(JSON.stringify(passItems))}, `user/changepassword`)).then(res => {
            let resdata = res.data;

            showAlert({ title: t("auth.password"), message: `${t("auth.passchangesuccess")}...`, btnText: t("setup.ok") })
            .then(res => Logout());
        }).catch(error => { 
            showAlert({ title: t("auth.password"), message: `${t("auth.passchangerror")}...${error.message}`, btnText: t("setup.ok") });
        });
    }

    return(
        <div className=''>
            <div className="row p-4">
                <div className="col p-4">
                    <div className="adminHead d-flex flex-row justify-content-between">
                        <span>My Profile</span>
                        {/* <div className="d-flex flex-row">
                            <button className="btn btn-sm ml-2" onClick={() => showPageForm(Dto.portals)}>{t("setup.new")} {t("portal.product")}</button>
                        </div> */}
                    </div>
                    <div className="adminBody">
                        <div className='row'>
                            <div className='col-xs-12 col-sm-6 col-md-5 col-lg-4'>
                                <div className='mb-2'>{t("setup.fname")}: <b>{user.firstName}</b></div>
                                <div className='mb-2'>{t("auth.email")}: <b>{user.email}</b></div>
                                <div className='mb-2'>{t("auth.password")}: <span className='badge badge-danger' role='button' onClick={showDialog}>Change password</span></div>
                            </div>
                            <div className='col-xs-12 col-sm-6 col-md-5 col-lg-4'>
                                <div className='mb-2'>{t("setup.lname")}: <b>{user.lastName}</b></div>
                                <div className='mb-2'>{t("auth.mobile")}: <b>{user.mobile}</b></div>
                                <div className='mb-2'>{t("auth.phone")}: <b>{user.phone}</b></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <StaticDialog isOpen={smShow} isCanClose={false} title={<h5>{t("auth.changepassword")}</h5>}
                onAfterClose={res => {
                    

                    if(passItems.curpass.trim() === ''){
                        showAlert({ title: t("auth.password"), message: `${t("auth.please")} ${t("auth.typecurpass")}...`, btnText: t("setup.ok") })
                        .then(res => smClose());
                    }else if(passItems.password.trim() === ''){
                        showAlert({ title: t("auth.password"), message: `${t("auth.please")} ${t("auth.newpassword")}...`, btnText: t("setup.ok") })
                        .then(res => smClose());
                    }else if(passItems.password.trim() === ''){
                        showAlert({ title: t("auth.password"), message: `${t("auth.passnotsame")}...`, btnText: t("setup.ok") })
                        .then(res => smClose());
                    }else if (res) { 
                        changePassword(); 
                        smClose();
                    }else{
                        smClose();
                    }
                }}>
                <InnerModal btns={[{ 'color': 'warning', 'label': t("setup.cancel") }, { 'color': 'success', 'label': `${t("auth.changepassword")}` }]}>
                    <div className="row">
                        <div className="col-10">
                            <div className="form-group">
                                <label for="">{t("auth.curpass")}: </label>
                                <input type="password" id="curpass" name="curpass" className="form-control form-control-sm" 
                                    value={passItems.curpass} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label for="">{t("auth.newpassword")}: </label>
                                <input type="password" id="password" name="password" className="form-control form-control-sm" 
                                    value={passItems.password} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label for="">{t("auth.retypepassword")}: </label>
                                <input type="password" id="repassword" name="repassword" className="form-control form-control-sm" 
                                    value={passItems.repassword} onChange={handleChange} />
                            </div>
                        </div>
                    </div>
                </InnerModal>
            </StaticDialog>
        </div>
    )
}

export default MyProfile;