import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { StaticDialog } from "react-st-modal";
import { GetJCookie, InnerModal } from "../../utils/MojaveLib";

function DropdownForm(props){

    const [type, setType] = useState(props.type);
    const [dropItems, setDropItems] = useState(props.dropItems);

    const {t, i18n} = useTranslation('translation');

    const dropdowns = GetJCookie('kyka-prepo');

    useEffect(() => {
        setType(props.type);

        let dropItems = props.dropItems;
        dropItems.pretype = props.type;
        setDropItems(dropItems);

    },[props.type, props.dropItems]);

    const handleChange = (e) => {
        e.preventDefault();

        const {name, value} = e.target;

        setDropItems(prevProps => ({...prevProps, [name]:value}));
    }

    const saveDropdown = () => props.saveDropdown({...dropItems});

    return(
        <StaticDialog isOpen={props.show} isCanClose={false} title={<h5>{(dropItems.id > 0) ? t("setup.edit") : t("setup.new")} {(type === '') ? t("setup.dropdown") : type}</h5>}
            onAfterClose={res => {
                props.onHide();


                if (res) { props.setType(dropItems.pretype); saveDropdown(); }
            }}>
            <InnerModal btns={[{ 'color': 'warning', 'label': t("setup.cancel") }, { 'color': 'success', 'label': `${t("setup.save")} ${(type === '') ? t('setup.dropdown') : type.replace('_', ' ')}` }]}>
                <div className="row">
                    <div className="col-10">
                        {/* <div className="form-group">
                            <label for="lang">Language: </label>
                            <select id="lang" name="lang" className="form-control form-control-sm" 
                                value={dropItems.lang} onChange={handleChange} disabled={type !== ''}>
                                    {dropdowns.language !== undefined && dropdowns.language.map(drop => 
                                    <option value={drop.prevalue}>{drop.prename}</option>)}
                            </select>
                        </div> */}
                        <div className="form-group">
                            <label for="">{t("setup.type")}: </label>
                            <input type="text" id="pretype" name="pretype" className="form-control form-control-sm" 
                                value={dropItems.pretype} onChange={handleChange} readOnly={type !== ''}/>
                        </div>
                        <div className="form-group">
                            <label for="">{t("setup.name")}: </label>
                            <input type="text" id="prename" name="prename" className="form-control form-control-sm" 
                                value={dropItems.prename} onChange={handleChange}/>
                        </div>
                        <div className="form-group">
                            <label for="">{t("setup.value")}: </label>
                            <input type="text" id="prevalue" name="prevalue" className="form-control form-control-sm" 
                                value={dropItems.prevalue} onChange={handleChange}/>
                        </div>
                        <div className="form-group">
                            <label for="">{t("setup.extravalue")}: </label>
                            <input type="number" id="prextra" name="prextra" className="form-control form-control-sm" 
                                value={dropItems.prextra} onChange={handleChange} min={0} step={'any'}/>
                        </div>
                    </div>
                </div>
            </InnerModal>
        </StaticDialog>
    );
}

export default DropdownForm;