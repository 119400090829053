import React, { useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';
import { GetCookie, GetJCookie, Loader } from "../../utils/MojaveLib";
import _ from "lodash";

function PageWelcome(props){

    const {t, i18n} = useTranslation('translation');

    useEffect(() => {
        i18n.changeLanguage(GetCookie('kyka-language'));
    },[i18n, GetCookie('kyka-language')]);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col text-center mt-4">{t("welcome.text")}</div>
            </div>
        </div>
    );
}

export default PageWelcome;