import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { DConnect, DecryptData, GetJCookie, HandleError, PostOptions, TableBase, showConfirm } from "../../utils/MojaveLib";
import PageForm from "./PageForm";
import { useTranslation } from "react-i18next";
import $ from 'jquery';
import axios from 'axios';
import Dto from "../../utils/serializers";
import _ from "lodash";

function PageList(props){

    const [page, setPage] = useState(props.page);
    const [portals, setPortals] = useState([]);
    const [categories, setCategories] = useState([]);
    const [users, setUsers] = useState([]);
    const [category, setCategory] = useState('');

    const {t, i18n} = useTranslation('translation');

    const dropdowns = GetJCookie('kyka-prepo');

    const columns = [
        {name: t("setup.title"), selector: row => row.title, sortable: true, maxWidth: '800px'},
        {name: 'SKU', selector: row => row.sku, sortable: true, width: '180px'},
        {name: t("setup.lastmodify"), selector: row => formatUser(row.modifiedBy, row), sortable: true, width: '150px'},
        {name: t("setup.status"), selector: row => formatStatus(row.status, row), sortable: true, width: '120px'},
        {name: '', selector: row => formatAction(row.id, row), sortable: true, width: '120px'},
    ];

    useEffect(() => {
        setPage(props.page);

        getInitData();

    },[props.page]);

    const getInitData = () => {
        DConnect().get(`user`).then(res => {
            let users = DecryptData(res.data);

            setUsers(users);
        }).catch(error =>HandleError(error));

        DConnect().get(`category`).then(res => {
            setCategories(res.data);
        }).catch(error =>HandleError(error));
    }

    const handleChange = (e) => {
        e.preventDefault();

        const {name, value} = e.target;

        setCategory(value);

        getPageList(value);
    }

    const getPageList = (category) => {
        DConnect().get(`portal/category/${category}`).then(res => {
            
            if(res.data.length > 0){
                setPortals(res.data);
            }else{
                setPortals([]);
            }
        }).catch(error =>HandleError(error));
    }

    const changeStatus = (item, status) => {
        let idx = _.findIndex(portals, item),
            newPortals = [...portals];

        newPortals[idx].status = status;
        setPortals(newPortals);
        item.status = status;

        axios(PostOptions('PUT',item, `portal/${item.id}`));
    }

    const deleteProduct = (row) => {
        showConfirm({title:`${t('portal.product')}`, message:`${t("portal.confirmImgDel")}...`, btnText:[`${t("setup.delete")}`,`${t("cancel")}`]}).then(res => {
            if(res){
                axios(PostOptions('DELETE', row, `portal/${row.id}`));

                DConnect().get(`product/portal/${row.id}`).then(res => {
                    if(res.data.length > 0){
                        let product = res.data[0];

                        axios(PostOptions('DELETE', product, `product/${product.id}`));

                        // DConnect().get(`media/owner/product/${product.id}`);
                    }
                }).catch(error =>HandleError(error));

                getPageList(category);
            }
        });
    }

    const formatAction = (cell, row) => <div style={{fontSize:'20px', lineHeight:'20px'}}>
        <i className='material-icons text-success' role="button" title={t("setup.edit")} onClick={() => showEditForm(row)}>edit</i> &nbsp; | &nbsp; 
        <i className='material-icons text-danger' role="button" title={t("setup.delete")} onClick={() => deleteProduct(row)}>delete</i>
    </div>;

    const formatUser = (cell, row) => {
        let username = users.filter(user => user.id === cell);

        return (username.length > 0) ? `${username[0].firstName} ${username[0].lastName}` : '-';
    }

    const formatStatus = (cell, row) => {
        return (cell == 0) ? 
        <span className="badge badge-danger" role="button" onClick={() => changeStatus(row, 1)}>{formatPrepo(cell,'status')}</span> :
        <span className="badge badge-success" role="button" onClick={() => changeStatus(row, 0)}>{formatPrepo(cell,'status')}</span>;
    }

    const formatPrepo = (cell, area) => {
        let proponame = dropdowns[area] !== undefined && dropdowns[area].filter(drop => drop.prevalue == cell);

        return proponame.length > 0 ? proponame[0].prename :  cell;
    }

    const showPageForm = () => {
        ReactDOM.render(<PageForm page={page} portals={Dto.portals} products={Dto.products} images={[]} prices={[]} productExtra={[]} />, 
        document.getElementById("pageCon"));
    }

    const showEditForm = (portal) => {
        DConnect().get(`portal/complete/${portal.id}`).then(res => {
            let resdata = res.data;



            ReactDOM.render(<PageForm page={page} portals={resdata.portal} products={resdata.product} 
                images={resdata.images} prices={resdata.prices} 
                productExtra={resdata.prices} />, 
            document.getElementById("pageCon"));
        }).catch(error =>HandleError(error));
    }

    return (
        <div className="row p-4">
            <div className="col p-4">
                <div className="adminHead d-flex flex-row justify-content-between">
                    <span>{t("welcome.products")}</span>
                    <div className="d-flex flex-row">
                        <select className="form-control form-control-sm" id="category" name="category" 
                            value={category} onChange={handleChange}>
                            <option value=''>-- {t("setup.select")} {t("setup.category")} --</option>
                            {categories.map(cat => 
                                <option value={cat.id}>{cat.categoryName}</option>)}
                        </select>
                        <button className="btn btn-sm ml-2" onClick={() => showPageForm(Dto.portals)}>{t("setup.new")} {t("portal.product")}</button>
                    </div>
                </div>
                <div className="adminBody">
                    <TableBase keys="id" data={portals} columns={columns} />
                </div>
            </div>
        </div>
    );
}

export default PageList;