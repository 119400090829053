import React, {useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';
import Dto from "../../utils/serializers";
import { DConnect, HandleError, showAlert } from "../../utils/MojaveLib";
import axios from "axios";
import { PostOptions } from "../../utils/MojaveLib";
import { GetJCookie } from "../../utils/MojaveLib";

function GeneralSetup(props){

    const [title, setTitle] = useState(props.title);
    const {t, i18n} = useTranslation('translation');
    const [setupItems, setSetupItems] = useState(Dto.setup);
    const [currencyItems, setCurrencyITems] = useState(Dto.currency);

    const dropdowns = GetJCookie('kyka-prepo');

    useEffect(() => {
        setTitle(props.title);

        getSetupData();

    },[props.title]);

    const getSetupData = () => {
        DConnect().get(`setup`).then(res => {

            if(res.data.length > 0){
                setSetupItems(res.data[0]);
            }
        }).catch(error =>HandleError(error));
    }

    const handleChange = (e) => {
        e.preventDefault();

        const {name, value} = e.target;

        setSetupItems(prevProps => ({...prevProps, [name]:value}));
    }

    const handleCurrencyChange = (e) => {
        e.preventDefault();

        const {name, value} = e.target;

        setCurrencyITems(prevProps => ({...prevProps, [name]:value}));
    }

    const submitForm = (e) => {
        e.preventDefault();

        let formData = {...setupItems},
            url = (formData.id > 0) ? `setup/${formData.id}` : `setup`,
            protocol = (formData.id > 0) ? `PUT`:'POST';

        axios(PostOptions(protocol,formData, url)).then(res => {
            let resdata = res.data;

            showAlert({ title: 'General Setup', message: `Setup saved successfully...`, btnText: 'OK' });
        }).catch(error => { 
            showAlert({ title: 'Setup Error', message: `Error saving setup...${error.message}`, btnText: 'OK' });
         });
    }

    return (
        <div>
            <div className="adminHead">{title}</div>
            <div className="adminBody">
                <form id="frmGeneralSetup" onSubmit={submitForm}>
                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-5">
                            <div className="form-group">
                                <label for="">{t("setup.company")}: </label>
                                <input type="text" className="form-control form-control-sm" id="companyName" name="companyName"
                                    onChange={handleChange} value={setupItems.companyName} required />
                            </div>
                            <div className="form-group">
                                <label for="">{t("setup.hotline")}: </label>
                                <input type="tel" className="form-control form-control-sm" id="hotline" name="hotline"
                                    onChange={handleChange} value={setupItems.hotline} required />
                            </div>
                            <div className="form-group">
                                <label for="">{t("auth.email")}: </label>
                                <input type="email" className="form-control form-control-sm" id="email" name="email"
                                    onChange={handleChange} value={setupItems.email} required />
                            </div>

                            <div className="form-group">
                                <label for="">{t('setup.momo')}: </label>
                                <input type="tel" className="form-control form-control-sm" id="momoNo" name="momoNo"
                                    onChange={handleChange} value={setupItems.momoNo} />
                            </div>
                            <div className="form-group">
                                <label for="">{t('setup.location')}: </label>
                                <input type="text" className="form-control form-control-sm" id="location" name="location"
                                    onChange={handleChange} value={setupItems.location} />
                            </div>

                            <div className="form-group">
                                <label for="">{t('setup.bankdetails')}: </label>
                                <textarea className="form-control form-control-sm" id="bank" name="bank"
                                    onChange={handleChange} value={setupItems.bank} />
                            </div>
                            <div className="form-group">
                                <label for="">{t('setup.privacy')}: </label>
                                <textarea className="form-control form-control-sm" id="privacy" name="privacy"
                                    onChange={handleChange} value={setupItems.privacy} />
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-5">
                            <div className="form-group">
                                <label for="">{t("setup.language")}: </label>
                                <select className="form-control form-control-sm" id="defaultLang" name="defaultLang"
                                    onChange={handleChange} value={setupItems.defaultLang} required>
                                    <option value=''>-- {t("setup.select")} {t("setup.language")} --</option>
                                    {dropdowns.language !== undefined &&  dropdowns.language.map(lang => 
                                        <option value={lang.prevalue}>{lang.prename}</option>)}
                                </select>
                            </div>
                            <div className="form-group">
                                <label for="">{t("setup.tax")}: </label>
                                <input type="number" className="form-control form-control-sm text-right" id="taxRate" name="taxRate"
                                    onChange={handleChange} value={setupItems.taxRate} min={0} step='any' required />
                            </div>
                            <div className="form-group">
                                <label for="">{t("setup.vat")}: </label>
                                <input type="number" className="form-control form-control-sm text-right" id="vatRate" name="vatRate"
                                    onChange={handleChange} value={setupItems.vatRate} min={0} step='any' required />
                            </div>
                            <div className="form-group">
                                <label for="">{t('setup.condition')}: </label>
                                <textarea className="form-control form-control-sm" id="productCondition" name="productCondition"
                                    onChange={handleChange} value={setupItems.productCondition} />
                            </div>
                            <div className="form-group">
                                <label for="">{t("setup.bcur")}: </label>
                                <select className="form-control form-control-sm" id="currencyPrefix" name="currencyPrefix"
                                    onChange={handleChange} value={setupItems.currencyPrefix} required>
                                    <option value=''>-- {t("setup.select")} {t("setup.currency")} --</option>
                                    {dropdowns.currency !== undefined &&  dropdowns.currency.map(currency => 
                                        <option value={currency.prevalue}>{currency.prename}</option>)}
                                </select>
                            </div>

                            {setupItems.currencyPrefix !== '' && dropdowns.currency !== undefined &&  dropdowns.currency.map((currency, idx) => 
                            currency.prevalue !== setupItems.currencyPrefix &&
                            <div className="form-group input-group input-group-sm">
                                <label className="mr-2">{currency.prename} rate: </label>
                                <input type="number" className="form-control form-control-sm" id="currency" name="currency"
                                    onChange={(e) => handleCurrencyChange(e, idx)} value={setupItems.currency} required min={0} step='any' />
                            </div>)}
                        </div>
                        <div className="col-10 mt-4 text-center">
                            <button type="submit" className="btn btn-info">{t("setup.save_changes")}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default GeneralSetup;