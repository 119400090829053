import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import StepWizard from 'react-step-wizard';
import { DConnect, GetJCookie, HandleError } from "../../utils/MojaveLib";
import { useTranslation } from "react-i18next";
import PageList from "./PageList";
import $ from 'jquery';
import PortalInfo from "./pageForms/PortalInfo";
import ProductImages from "./pageForms/ProductImages";
import Complete from "./pageForms/Complete";
import Dto from "../../utils/serializers";
import ProductPrices from "./pageForms/ProductPrices";
import _ from "lodash";
import ProductExtra from "./pageForms/ProductExtra";

function PageForm(props){

    const [page, setPage] = useState(props.page);
    const [portalItems, setPortalItems] = useState(props.portals);
    const [productItems, setProductItems] = useState(props.products);
    const [productExtraItems, setProductExtraItems] = useState(props.productExtra);
    const [imageItems, setImageItems] = useState([]);
    const [dbImages, setDbImages] = useState(props.images);
    const [priceItems, setPriceItems] = useState(props.prices);
    const [sortOrderEdit, setSortOrderEdit] = useState({});

    const [categories, setCategories] = useState([]);
    const [categorySubs, setCategorySubs] = useState([]);

    const [makes, setMakes] = useState([]);

    const {t, i18n} = useTranslation('translation');

    const dropdowns = GetJCookie('kyka-prepo');

    useEffect(() => {
        initData();

        setPage(props.page);
        setPortalItems(props.portals);
        setProductItems(props.products);
        setDbImages(props.images);
        setPriceItems(props.prices);
        setProductExtraItems(props.productExtra);


    },[i18n, props.page, props.portals, props.products, props.images, props.prices]);

    const initData = () => {
        DConnect().get(`make`).then(res => {
            setMakes(res.data);
        }).catch(error =>HandleError(error));

        DConnect().get(`category`).then(res => {
            setCategories(res.data);
        }).catch(error =>HandleError(error));

        DConnect().get(`subcategory`).then(res => {
            let categorySubs = _.groupBy(res.data, 'categoryId');

            setCategorySubs(categorySubs);
        }).catch(error =>HandleError(error));
    }

    const handleChange = (area, items) => {
        if(area === 0){
            setPortalItems(items);
        }else if(area === 1){
            setProductItems(items);
        }else if(area === 2){
            setImageItems(items);
        }else if(area === 3){
            setPriceItems(items);
        }else if(area === 4){
            setSortOrderEdit(items);
        }else if(area === 5){
            setDbImages(items);
        }else if(area === 6){
            setProductExtraItems(items);
        }
    }

    const doNavigate = (itm, pos) => {
        itm.goToStep(pos);
        
        // $('.list-group li.active').removeClass('active').addClass('visited');
        // $(`.list-group li:nth-child(${pos})`).removeClass('visited').addClass('active');
    }

    const goBack = () => {
        // $(`[id^="menuBtn_"]`).removeClass('disabled');
        // $(`[id^="menuBtn_"]`).removeAttr('disabled');

        ReactDOM.render(<PageList page={page} />, document.getElementById("pageCon"));
    }

    return (
        <div className="row p-4">
            <div className="col-xs-12 col-md-9 col-lg-10 p-4">
                <div className="adminHead d-flex flex-row justify-content-between">
                    <span>{t('welcome.products')}</span>
                    <div className="d-flex flex-row">
                        <button className="btn btn-sm ml-2" onClick={goBack}>{t("setup.cancel")}</button>
                    </div>
                </div>
                <div className="adminBody">
                    <StepWizard isLazyMount>
                        <PortalInfo  doNavigate={doNavigate} handleChange={handleChange} portalItems={portalItems} 
                            productItems={productItems} makes={makes} categories={categories}
                            categorySubs={categorySubs} selectedOptions={props.selectedOptions} />
                        <ProductExtra  doNavigate={doNavigate} handleChange={handleChange}  
                            productItems={productItems} portalItems={portalItems} />
                        <ProductPrices doNavigate={doNavigate} handleChange={handleChange} priceItems={priceItems} 
                            productExtraItems={productExtraItems} productItems={productItems} />
                        <ProductImages doNavigate={doNavigate} handleChange={handleChange} imageItems={imageItems} 
                            dbImages={dbImages} product={productItems} sortOrderEdit={sortOrderEdit} />
                        <Complete doNavigate={doNavigate} handleChange={handleChange} portalItems={portalItems} 
                            productItems={productItems} priceItems={priceItems} imageItems={imageItems} page={page}
                            makes={makes} dbImages={dbImages} sortOrderEdit={sortOrderEdit} productExtraItems={productExtraItems} />
                    </StepWizard>
                </div>
            </div>
            {/* <div className="col-xs-12 col-md-3 col-lg-2 p-4  mt-4 adminBtnCon">
                Images
            </div> */}
        </div>
    );
}

export default PageForm;