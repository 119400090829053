import React, { useEffect, useState } from "react";
import { GetJCookie, InnerModal } from "../../utils/MojaveLib";
import { StaticDialog } from "react-st-modal";
import { useTranslation } from "react-i18next";

function PortalForm(props){

    const [type, setType] = useState(props.type);
    const [portalItems, setPortalItems] = useState(props.portalItems);

    const {t, i18n} = useTranslation('translation');

    const dropdowns = GetJCookie('kyka-prepo');

    useEffect(() => {
        setType(props.type);

        let portalItems = props.portalItems;
        portalItems.pretype = props.type;
        setPortalItems(portalItems);

    },[props.type, props.portalItems]);

    const handleChange = (e) => {
        e.preventDefault();

        const {name, value} = e.target;

        setPortalItems(prevProps => ({...prevProps, [name]:value}));
    }

    const savePortal = () => props.savePortal({...portalItems});

    return(
        <StaticDialog isOpen={props.show} isCanClose={false} title={<h5>{(portalItems.id > 0) ? t("setup.edit") : t("setup.new")} {t("setup.portal")}</h5>}
            onAfterClose={res => {
                props.onHide();


                if (res) { savePortal(); }
            }}>
            <InnerModal btns={[{ 'color': 'warning', 'label': t("setup.cancel") }, { 'color': 'success', 'label': `${t("setup.save")} ${t('setup.portal')}` }]}>
                <div className="row">
                    <div className="col-10">
                        <div className="form-group">
                            <label for="page">Page: </label>
                            <select id="page" name="page" className="form-control form-control-sm" 
                                value={portalItems.page} onChange={handleChange} required>
                                    <option value="">-- {t("setup.select")} --</option>
                                    {dropdowns.page !== undefined && dropdowns.page.map(drop => 
                                    <option value={drop.prevalue}>{drop.prename}</option>)}
                            </select>
                        </div>
                        {portalItems.page == 1 &&
                        <div className="form-group">
                            <label for="pageType">Page Type: </label>
                            <select id="pageType" name="pageType" className="form-control form-control-sm" 
                                value={portalItems.pageType} onChange={handleChange} required>
                                    <option value="">-- {t("setup.select")} --</option>
                                    {dropdowns.home_items !== undefined && dropdowns.home_items.map(drop => 
                                    <option value={drop.prevalue}>{drop.prename}</option>)}
                            </select>
                        </div>}
                        {portalItems.page == 2 &&
                        <div className="form-group">
                            <label for="pageType">Page Type: </label>
                            <select id="pageType" name="pageType" className="form-control form-control-sm" 
                                value={portalItems.pageType} onChange={handleChange} required>
                                    <option value="">-- {t("setup.select")} --</option>
                                    {dropdowns.about_kyka !== undefined && dropdowns.about_kyka.map(drop => 
                                    <option value={drop.prevalue}>{drop.prename}</option>)}
                            </select>
                        </div>}
                        {portalItems.page > 2 &&
                        <div className="form-group">
                            <label for="pageType">Page Type: </label>
                            <select id="pageType" name="pageType" className="form-control form-control-sm" 
                                value={portalItems.pageType} onChange={handleChange} required>
                                    <option value="">-- {t("setup.select")} --</option>
                                    {dropdowns.page_type !== undefined && dropdowns.page_type.map(drop => 
                                    <option value={drop.prevalue}>{drop.prename}</option>)}
                            </select>
                        </div>}
                        <div className="form-group">
                            <label for="">{t("setup.title")}: </label>
                            <input type="text" id="title" name="title" className="form-control form-control-sm" 
                                value={portalItems.title} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label for="">{t("setup.content")}: </label>
                            <textarea id="content" name="content" className="form-control form-control-sm" 
                                value={portalItems.content} onChange={handleChange} required rows={5} />
                        </div>
                    </div>
                </div>
            </InnerModal>
        </StaticDialog>
    );
}

export default PortalForm;