// eslint-disable-next-line
import React from 'react';

const Dto = () => {}

const login = {
    'email':'', 
    'password':''
};

const addresses = {
    'id':0,
    'userId':0,
    'streetAddress':'',
    'city':0,
    'firstName':'',
    'lastName':'',
    'region':0,
    'mobile':'',
    'popularPlace':'',
    'longitude':0.0,
    'latitude':0.0,
    'gtGps':'',
}

const carts ={
    'id':0,
    'profileId': 0,
    'portalId': 0,
    'productId': 0,
    'quantity': 0,
    'unitPrice': 0,
    'tax': 0,
    'color': '',
    'extra': '',
    'freight': 0,
    'delivery': 0,
    'discount': 0,
    'status': 0,
    'ipAddress': ''
}

const category ={
    'id':0,
    'batchId':0,
    'language':'en',
    'categoryName':'',
    'icon':'',
    'status':1,
    'createdBy':0,
    'modifiedBy':0,
}

const productExtra ={
    'id':0,
    'productId':0,
    'extraType':'',
    'quantity':0,
    'qty1':0,
    'qty2':0,
    'qty3':0,
    'amount1':0,
    'amount2':0,
    'amount3':0,
    'discount1':0,
    'discount2':0,
    'discount3':0,
    'status':1,
    'createdBy':0,
    'modifiedBy':0,
}

const categorySub ={
    'id':0,
    'categoryId':0,
    'name':'',
    'icon':'',
}

const cities ={
    'id':0,
    'regionId':0,
    'cityName':'',
}

const clientActivities ={
    'id':0,
    'userId':0,
    'activityType':0,
    'productId':0,
}

const clientBalance ={
    'id':0,
    'userId':0,
    'balanceAmount':0.0,
    'cartTotal':0.0,
    'lastPayAmount':0.0,
    'lastPayDate':new Date(),
}

const journal ={
    'id':0,
    'userId':0,
    'payType':0,
    'payTypeDetail':'',
    'payTypeName':'',
    'receiptNo':'',
    'transType':'',
    'narration':'',
    'openBal':0.0,
    'closeBal':0.0,
    'createdBy':0,
}

const makes ={
    'id':0,
    'batchCode':0,
    'language':'',
    'makeName':'',
    'status':1,
    'createdBy':0,
    'modifiedBy':0,
}

const makeModels ={
    'id':0,
    'makeId':0,
    'modelName':'',
}

const multimedia ={
    'id':0,
    'mediaType':0,
    'owner':'',
    'ownerId':0,
    'caption':'',
    'sortOrder':0,
    'filename':'',
}

const portals ={
    'id':0,
    'portalCode':0,
    'page':0,
    'pageType':0,
    'language':'',
    'title':'',
    'content':'',
    'taxable':false,
    'avgRatings':0.0,
    'views':0,
    'status':0,
    'createdBy':0,
    'modifiedBy':0,
}

const products ={
    'id':0,
    'portalId':0,
    'sku':'',
    'shippedSource':'',
    'make':0,
    'category':0,
    'categorySub':0,
    'features':'',
    'description':'',
    'specs':'',
    'unitOfMeasure':0,
    'unitPcs':0,
    'typeName0':'',
    'extraType0':0,
    'typeName1':'',
    'extraType1':0,
    'status':0,
    'weight':0,
    'height':0,
    'length':0,
    'width':0,
}

const prepopulate ={
    'id':0,
    'lang':'en',
    'pretype':'',
    'displayType':'',
    'prename':'',
    'prevalue':'',
    'prextra':0,
    'createdBy':0,
    'modifiedBy':0,
}

const prices ={
    'id':0,
    'productId':0,
    'minQty':1,
    'maxQty':1,
    'amount':0,
    'discountRate':0,
    'status':0,
    'createdBy':0,
    'modifiedBy':0,
}

const promotion ={
    'id':0,
    'promoName':'',
    'productId':'',
    'startDate':new Date(),
    'endDate':new Date(),
    'promoType':0,
    'amount':0.0,
    'amountType':1,
    'lowLimit': 0,
    'highLimit': 0,
    'usageType': 1,
    'status':1,
    'createdBy':0,
    'modifiedBy':0
}

const promoDetails = {
    'id':0,
    'userId': 0,
    'promoId':0
}

const regions ={
    'id':0,
    'regionCode':'',
    'language':'en',
    'regionName':'',
}

const reviews ={
    'id':0,
    'userId':0,
    'productId':0,
    'title':'',
    'review':'',
    'ratings':0,
}

const roles ={
    'id':0,
    'roleName':'',
    'userLevel':0,
    'features':'',
    'status':1,
    'createdBy':0,
    'modifiedBy':0,
}

const setup ={
    'id':0,
    'companyName':'',
    'hotline':'',
    'email':'',
    'defaultLang':'',
    'currencyPrefix':'',
    'taxRate':0.0,
    'vatRate':0.0,
    'location':'',
    'momoNo': '',
    'bank':'',
    'privacy':'',
    'productCondition':''
}

const users ={
    'id':0,
    'username':'',
    'email':'',
    'mobile':'',
    'phone':'',
    'password':'',
    'mobileVerified':false,
    'emailVerified':false,
    'lastLogin':new Date(),
    'lastPassChange':new Date(),
    'avatar':'',
    'firstName':'',
    'lastName':'',
    'status':0,
    'createdBy':0,
    'modifiedBy':0,
}

const wishList ={
    'id':0,
    'userId':0,
    'productId':0,
    'status':0,
}

const pickupPoint = {
    'id':0,
    'city':0,
    'location':'',
    'latitude':0.0,
    'longitude':0.0,
    'mobile':'',
    'email':'',
    'phone':'',
    'contactPerson':''
}

const deliverySetup = {
    'id':0,
    'origin':1,
    'destination':0,
    'transitPeriod':0,
    'periodType':1,
    'chargeType':1,
    'unitOfMeasure':7,
    'service':2,
    'multiplier':0,
    'pickupPoint':0,
    'status':1
}

const currency = {
    'id':0,
    'currency':0,
    'isBase':0,
    'exchangeRate':0,
    'modifiedby':0,
}

Dto.login = login;
Dto.addresses = addresses;
Dto.carts = carts;
Dto.category = category;
Dto.categorySub = categorySub;
Dto.productExtra = productExtra;
Dto.cities = cities;
Dto.clientActivities = clientActivities;
Dto.clientBalance = clientBalance;
Dto.journal = journal;
Dto.makes = makes;
Dto.makeModels = makeModels;
Dto.multimedia = multimedia;
Dto.portals = portals;
Dto.prepopulate = prepopulate;
Dto.products = products;
Dto.prices = prices;
Dto.promotion = promotion;
Dto.promoDetails = promoDetails;
Dto.regions = regions;
Dto.reviews = reviews;
Dto.roles = roles;
Dto.setup = setup;
Dto.users = users;
Dto.wishList = wishList;
Dto.deliverySetup = deliverySetup;
Dto.pickupPoint = pickupPoint;
Dto.currency = currency;

export default Dto;

export {
    login, addresses, carts, category, categorySub, cities, clientActivities,
    clientBalance, journal, makes, makeModels, multimedia, portals, wishList,
    prepopulate, prices, promotion, regions, reviews, roles, setup, users,
    products, deliverySetup, pickupPoint, promoDetails, productExtra,
    currency
}