import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { useTranslation } from "react-i18next";
import { DConnect, GetJCookie, GetUser, HandleError, PostOptions, TableBase, showAlert } from "../../utils/MojaveLib";
import axios from "axios";
import Dto from "../../utils/serializers";
import CityForm from "./CityForm";
import Region from "./Region";

function City(props){

    const [title, setTitle] = useState(props.title);
    const [subTitle, setSubTitle] = useState(props.subTitle);
    const [regionId, setRegionId] = useState(props.regionId);
    const [cityItems, setCityItems] = useState(Dto.cities);
    const [cities, setCities] = useState([]);
    const [users, setUsers] = useState(props.users);
    const [smShow, setSmShow] = useState(false);

    const {t, i18n} = useTranslation('translation');

    const dropdowns = GetJCookie('kyka-prepo');

    const columns = [
        {name: `${t("setup.city")} ${t("setup.name")}`, selector: row => row.cityName, sortable: true},
        // {name: t("setup.icon"), selector: row => <i className="material-icons">{row.icon}</i>, sortable: true},
        // {name: t("setup.lastmodify"), selector: row => formatUser(row.modifiedBy, row), sortable: true},
        {name: '', selector: row => formatAction(row.id, row), sortable: true},
    ];

    useEffect(() => {
        setUsers(props.users);
        setTitle(props.title);
        setSubTitle(props.subTitle);
        setRegionId(props.regionId);

        getInitData(props.regionId);
    },[i18n, props.users, props.title, props.regionId, props.subTitle]);

    const getInitData = (regionId) => {
        if(regionId === undefined || regionId <= 0){
            return;
        }
        
        DConnect().get(`city/?regionId=${regionId}`).then(res => {

            if(res.data.length > 0){
                setCities(res.data);
            }
        }).catch(error =>HandleError(error));
    }

    const showDialog = (cityItems) => {
        setCityItems(cityItems);

        setSmShow(true);
    }

    const smClose = () => setSmShow(false);

    const saveCity = (formData) => {
        let url =  `city`, protocol = 'POST';

        if(formData.id > 0){
            url = `city/${formData.id}`;
            protocol = 'PUT';
        }else{
            formData.regionId = regionId;
        }

        // alert(url)
        axios(PostOptions(protocol,formData, url)).then(res => {
            let resdata = res.data;

            getInitData(regionId);

            showAlert({ title: t("setup.cities"), message: `${t("setup.city")} ${t("setup.saved_success")}...`, btnText: t("setup.ok") });
        }).catch(error => { 
            showAlert({ title: t("setup.cities"), message: `${t("setup.saved_error")} ${t("setup.city")}...${error.message}`, btnText: t("setup.ok") });
        });
    }

    const formatAction = (cell, row) => <div style={{fontSize:'20px', lineHeight:'20px'}}>
        <i className='material-icons text-success' role="button" title={t("setup.edit")}  onClick={() => showDialog(row)}>edit</i> &nbsp; | &nbsp; 
        <i className='material-icons text-danger' role="button" title={t("setup.delete")}>delete</i>
    </div>;

    const goBack = () => ReactDOM.render(<Region title={title} />, document.getElementById("adminCon"));

    return(
        <div>
            <div className="adminHead d-flex flex-row justify-content-between">
                <span>{subTitle} {t("setup.cities")}</span>
                <div className="d-flex flex-row">
                    <button className="btn btn-sm btn-warning ml-2" onClick={goBack}>{t("setup.back")}</button>
                    <button className="btn btn-sm ml-2" onClick={() => showDialog({...Dto.cities})}>{t("setup.new")} {t("setup.cities")}</button>
                </div>
            </div>
            <div className="adminBody">
                <TableBase keys="id" data={cities} columns={columns} striped dense hover responsive />
            </div>

            <CityForm show={smShow} onHide={smClose} saveCity={saveCity} cityItems={cityItems} />
        </div>
    );
}

export default City;