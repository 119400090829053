import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    MDBCarousel,
    MDBCarouselItem,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane
  } from 'mdb-react-ui-kit';
import nl2br from 'react-nl2br';
import $ from 'jquery';
import axios from 'axios';
import ReactDOM from 'react-dom';
import { BaseURL, CurFormat, GetJCookie, HandleError, Loader, PostImageOptions, PostOptions, showAlert } from "../../../utils/MojaveLib";
import PageList from "../PageList";
import _ from "lodash";

function Complete(props){

    const [page, setPage] = useState(props.page);
    const [portalItems, setPortalItems] = useState(props.portalItems);
    const [productItems, setProductItems] = useState(props.productItems);
    const [imageItems, setImageItems] = useState(props.imageItems);
    const [priceItems, setPriceItems] = useState(props.priceItems);
    const [dbImages, setDbImages] = useState(props.dbImages);
    const [sortOrderEdit, setSortOrderEdit] = useState(props.sortOrderEdit);
    const [productExtraItems, setProductExtraItems] = useState(props.productExtraItems);

    const [formItems, setFormItems] = useState({'opt0':'', 'opt1':''});
    const [isLoading, setLoading] = useState(false);

    const [makes, setMakes] = useState(props.makes);

    const [basicActive, setBasicActive] = useState('tab1');

    const {t, i18n} = useTranslation('translation');

    const dropdowns = GetJCookie('kyka-prepo');

    useEffect(() => {
        setPage(props.page);
        setMakes(props.makes);
        setDbImages(props.dbImages);
        setImageItems(props.imageItems);
        setPriceItems(props.priceItems);
        setPortalItems(props.portalItems);
        setProductItems(props.productItems);
        setSortOrderEdit(props.sortOrderEdit);
        setProductExtraItems(props.productExtraItems);

    },[i18n, props.page, props.portalItems, props.productItems, props.sortOrderEdit, 
        props.imageItems, props.priceItems, props.makes, props.dbImages, props.productExtraItems]);

    const handleChange = (e) => {
        e.preventDefault();

        const {name, value} = e.target;

        setFormItems(prevProps => ({...prevProps, [name]:value}));
    }

    const handleBasicClick = (value) => {
        if (value === basicActive) {
            return;
        }
    
        setBasicActive(value);
    };

    const formatPrepo = (cell, area) => {
        let preponame = dropdowns[area]!==undefined && dropdowns[area].filter(drop => drop.prevalue == cell);

        return (preponame.length > 0) ? preponame[0].prename : '';
    }

    const formatMake = (cell, row) => {
        let makeName = makes !== undefined && makes.filter(make => make.id == cell);

        return (makeName.length > 0) ? makeName[0].makeName : cell;
    }

    const showPrice = () => {
        let extraType = formItems.opt0;

        if(formItems.opt1 !== ''){
            extraType += `:${formItems.opt1}`;
        }

        let prices = (extraType !== '') ? productExtraItems.filter(pextra => pextra.extraType === extraType)[0] : productExtraItems[0];

        // alert(JSON.stringify(prices))
        return <div className="d-flex flex-row">
            <div className="text-center mr-4">
                <div><b>{prices.qty1}-{prices.qty2-1} {formatPrepo(productItems.unitOfMeasure, 'unit_of_measure')}</b></div>
                <div className="color-danger"><h4>GH&cent;{CurFormat(prices.amount1)}</h4></div>
            </div>
            <div className="text-center mr-4">
                <div><b>{prices.qty2}-{prices.qty3-1} {formatPrepo(productItems.unitOfMeasure, 'unit_of_measure')}</b></div>
                <div className="color-danger"><h4>GH&cent;{CurFormat(prices.amount2)}</h4></div>
            </div>
            <div className="text-center mr-4">
                <div><b>{prices.qty3} {formatPrepo(productItems.unitOfMeasure, 'unit_of_measure')} and above</b></div>
                <div className="color-danger"><h4>GH&cent;{CurFormat(prices.amount3)}</h4></div>
            </div>
        </div>;
    }

    const saveProduct = () => {
        setLoading(true);

        let portalData = {...portalItems},
            productData = {...productItems},
            productExtra = [...productExtraItems],
            imageData = [...imageItems];

            portalData.page = 3;
            portalData.pageType = 1;

        if(portalData.id === 0){
            productData.folder = productData.category;
        }

        // console.log(JSON.stringify(productExtra));
        // return;

        let formData = {'portal': portalData, 'product': productData, 'price': productExtra},
            protocol = portalData.id > 0 ? 'PUT' : 'POST',
            url = portalData.id > 0 ? `portal/complete/${portalData.id}` : `portal/complete`;

        
        axios(PostOptions(protocol,formData, url)).then(res => {
            let resdata = res.data;

            Promise.all(imageData.map(async (pic, idx) => {
                if(pic.image !== ''){
                    let formData = new FormData(),
                        mediaType = (pic.ext === 'mp4') ? 2 : 1;
                    formData.append('filename', `${resdata.id}_img_${pic.sortOrder}.${pic.ext}`);
                    formData.append('foldername', `${productData.category}`);
                    formData.append('imagefile', pic.image);


                    let multimedia = {
                        'id':0, 'mediaType':mediaType, 'owner':'product', 'ownerId':resdata.id, 
                        'caption':'', 'sortOrder':pic.sortOrder, 'filename':`${resdata.id}_img_${pic.sortOrder}.${pic.ext}`
                    };

                    await axios(PostOptions('POST', {data: multimedia}, `media`));

                    await axios.post(`${BaseURL()}api/v1.0/fileupload`,formData).then(res => {
                        // console.log(res.data);
                    }).catch(error => HandleError(error));
                }
            })).then(() => {
                let sortOrder = Object.keys(sortOrderEdit);

                if(sortOrder.length > 0){
                    axios(PostOptions('POST', {data:{...sortOrderEdit}}, `media/reorder`))
                    .then(res => {
                        // console.log(res.data);
                    }).catch(error => HandleError(error));
                }

                showAlert({ title: t("portal.product"), message: `${t("portal.product")} ${t("setup.saved_success")}...`, btnText: t("setup.ok") })
                .then(res => goBack());

                setLoading(false);
            });
        }).catch(error => { 
            showAlert({ title: t("portal.product"), message: `${t("setup.saved_error")} ${t("portal.product")}...${error.message}`, btnText: t("setup.ok") });
            setLoading(false);
        });
    }

    const goBack = () => {
        // $(`[id^="menuBtn_"]`).removeClass('disabled');
        // $(`[id^="menuBtn_"]`).removeAttr('disabled');

        window.location.reload(true);

        // ReactDOM.render(<PageList page={page} />, document.getElementById("pageCon"));
    }

    if(isLoading){
        return <Loader />;
    }

    return(
        <div>
            <h5 className="text-primary mt-2 mb-0 d-flex flex-row justify-content-between">
                <span>{t("portal.preview")} {t("portal.product")}</span>
            </h5>
            <hr className="mt-0" />
            <div className="d-flex flex-row">
                <div className="p-4" style={{width:'400px'}}>
                    {((imageItems!==undefined && imageItems.length>0) || (dbImages!==undefined && dbImages.length > 0))&& 
                    <MDBCarousel showControls showIndicators interval={false}>
                        {_.orderBy(imageItems, 'sortOrder').map((image, idx) =>
                        <MDBCarouselItem
                        className='w-100 d-block'
                        itemId={(dbImages.length + idx + 1)}
                        src={image.file}
                        alt='...'
                        />)}
                        {_.orderBy(dbImages, 'sortOrder').map((image, idx) =>
                        <MDBCarouselItem
                        className='w-100 d-block' itemId={(idx + 1)}
                        src={`${BaseURL()}pic/${productItems.folder}/${image.filename}`}
                        alt='...'
                        />)}
                    </MDBCarousel>
                    }
                    <div className="text-center">{portalItems.title}</div>
                    <div className="text-center mt-4">
                        <button className="btn btn-success btn-sm pl-4 pr-4" onClick={saveProduct}>{t("setup.save")} {t("portal.product")}</button>
                    </div>
                </div>
                <div className="p-4">
                    <h3 className="">{portalItems.content}</h3>
                    <div className="d-flex flex-row">
                        <div className="mr-3">SKU: <b>{productItems.sku}</b></div>
                    </div>
                    <div className="d-flex flex-row">
                        <div className="mr-3">Shipped From: <b>{productItems.shippedSource} ({formatPrepo(productItems.freightType, 'freight_type')})</b></div>
                    </div>
                    <div className="d-flex flex-row">
                        <div className="mr-3">Brand: <b>{formatMake(productItems.make, productItems)}</b></div>
                    </div>
                    <div className="d-flex flex-row">
                        <div className="mr-3">Inventory: <b>{productItems.quantity} {formatPrepo(productItems.unitOfMeasure,'unit_of_measure')}</b> </div>
                        <small className="text-danger">(1 {formatPrepo(productItems.unitOfMeasure,'unit_of_measure')} = {productItems.unitPcs} {t("portal.pcs")})</small>
                    </div>
                    <hr />
                    {productItems.typeName1}
                    <div className="d-flex flex-row ">
                        {productItems.extraType0 > 0 &&
                        <div className="form-group form-group-sm mr-4">
                            <label className="mr-3">{formatPrepo(productItems.extraType0,'product_extra_type')}: </label>
                            <select className="form-control form-control-sm" name="" style={{width: '200px'}}
                                onChange={handleChange} value={formItems.opt0}>
                                {productItems.typeName0.split(',').map(tname => tname !== '' &&
                                    <option value={tname}> {tname} </option>)}
                            </select>
                        </div>}
                        {productItems.extraType1 > 0 &&
                        <div className="form-group form-group-sm">
                            <label className="mr-3">{formatPrepo(productItems.extraType1,'product_extra_type')}: </label>
                            <select className="form-control form-control-sm" name="" style={{width: '200px'}}
                                onChange={handleChange} value={formItems.opt1}>
                                {productItems.typeName1.split(',').map(tname => tname !== '' &&
                                    <option value={tname}> {tname} </option>)}
                            </select>
                        </div>}
                    </div>
                    {showPrice()}
                    <hr />
                    
                </div>
            </div>
            <div className="" style={{overflow:'hidden',paddingLeft:'40px'}}>
                <MDBTabs className='mb-3'>
                    <MDBTabsItem>
                        <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
                            {t("portal.description")}
                        </MDBTabsLink>
                    </MDBTabsItem>
                    <MDBTabsItem>
                        <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
                            {t("portal.features")}
                        </MDBTabsLink>
                    </MDBTabsItem>
                    <MDBTabsItem>
                        <MDBTabsLink onClick={() => handleBasicClick('tab3')} active={basicActive === 'tab3'}>
                            {t("portal.specs")}
                        </MDBTabsLink>
                    </MDBTabsItem>
                    <MDBTabsItem>
                        <MDBTabsLink onClick={() => handleBasicClick('tab4')} active={basicActive === 'tab4'}>
                            {t("portal.review")}
                        </MDBTabsLink>
                    </MDBTabsItem>
                </MDBTabs>

                <MDBTabsContent style={{minHeight:'400px'}}>
                    <MDBTabsPane show={basicActive === 'tab1'}>{nl2br(productItems.description)}</MDBTabsPane>
                    <MDBTabsPane show={basicActive === 'tab2'}>{nl2br(productItems.features)}</MDBTabsPane>
                    <MDBTabsPane show={basicActive === 'tab3'}>{nl2br(productItems.specification)}</MDBTabsPane>
                    <MDBTabsPane show={basicActive === 'tab4'}>- - -</MDBTabsPane>
                </MDBTabsContent>
            </div>
        </div>
    )
}

export default Complete;