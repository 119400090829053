import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import ReactDOM from "react-dom";
import { 
    BaseURL,
    DConnect, DecryptData, GetJCookie, 
    GetUser, HandleError, PostOptions, 
    TableBase, showAlert, showConfirm 
} from "../../utils/MojaveLib";
import CategoryForm from "./CategoryForm";
import Dto from "../../utils/serializers";
import axios from "axios";
import SubCategory from "./SubCategory";
import _ from "lodash";

function Category(props){

    const [title, setTitle] = useState(props.title);
    const [categories, setCategories] = useState([]);
    const [users, setUsers] = useState([]);
    const [categoryItems, setCategoryItems] = useState(Dto.category);
    const [image, setImage] = useState({ 'image': '', 'file': '', 'ext': ''});
    const [catMedia, setCatMedia] = useState({});
    const [smShow, setSmShow] = useState(false);

    const {t, i18n} = useTranslation('translation');

    const dropdowns = GetJCookie('kyka-prepo');
    const columns = [
        {name: `${t("setup.category")} ${t("setup.name")}`, selector: row => row.categoryName, sortable: true},
        {name: t("setup.icon"), selector: row => formatImage(row.id, row), sortable: true},
        {name: t("setup.lastmodify"), selector: row => formatUser(row.modifiedBy, row), sortable: true},
        {name: '', selector: row => formatAction(row.id, row), sortable: true},
    ];

    useEffect(() => {
        setTitle(props.title);

        getInitData();

    },[i18n, props.title]);

    const getInitData = () => {
        DConnect().get(`user`).then(res => {
            let users = DecryptData(res.data);

            setUsers(users);
        }).catch(error =>HandleError(error));

        DConnect().get(`media?owner=category`).then(res => {
            let catMedia = _.groupBy(res.data, 'ownerId');

            setCatMedia(catMedia);
        }).catch(error =>HandleError(error));
        
        DConnect().get(`category`).then(res => {

            if(res.data.length > 0){
                setCategories(res.data);
            }
        }).catch(error =>HandleError(error));
    }

    const showDialog = (categoryItems) => {
        setCategoryItems(categoryItems);

        setSmShow(true);
    }

    const smClose = () => setSmShow(false);

    const saveCategory = (catData, image) => {
        let url =  `category`, protocol = 'POST';

        if(catData.id > 0){
            url = `category/${catData.id}`;
            protocol = 'PUT';
            catData.modifiedBy = GetUser().id;
        }else{
            catData.createdBy = GetUser().id;
        }

        axios(PostOptions(protocol,catData, url)).then(res => {
            let resdata = res.data;

            let formData = new FormData(), catid = (catData.id > 0 ? catData.id : resdata.id), filename = `category_${catid}`;
            formData.append('filename', `${filename}.${image.ext}`);
            formData.append('foldername', `category`);
            formData.append('imagefile', image.image);

            let multimedia = {
                'id':0, 'mediaType':1, 'owner':'category', 'ownerId':catid, 
                'caption':'', 'sortOrder':0, 'filename':`${filename}.${image.ext}`
            };

            axios(PostOptions('POST', {data: multimedia}, `media`));

            axios.post(`${BaseURL()}api/v1.0/fileupload`,formData).then(res => {
                getInitData();

                showAlert({ title: t("setup.category"), message: `${t("setup.category")} ${t("setup.saved_success")}...`, btnText: t("setup.ok") });
            }).catch(error => HandleError(error));
        }).catch(error => { 
            showAlert({ title: t("setup.category"), message: `${t("setup.saved_error")} ${t("setup.category")}...${error.message}`, btnText: t("setup.ok") });
        });
    }

    const deleteCategeory = (category) => {
        showConfirm({title:`${t('setup.category')}`, message:`${t("portal.confirmImgDel")}...`, btnText:[`${t("setup.delete")}`,`${t("cancel")}`]}).then(res => {
            if(res){
                axios(PostOptions('DELETE', {}, `category/${category.id}`))
                .then(res => {
                    getInitData();
                })
                .catch(err => console.log(err.message));
            }
        });
    }

    const formatAction = (cell, row) => <div style={{fontSize:'20px', lineHeight:'20px'}}>
        <i className='material-icons text-success' role="button" title={t("setup.edit")}  onClick={() => showDialog(row)}>edit</i> &nbsp; | &nbsp; 
        <i className='material-icons text-success' role="button" title={t("setup.subcats")} onClick={() => showCategorySub(row)}>book</i> &nbsp; | &nbsp; 
        <i className='material-icons text-danger' role="button" title={t("setup.delete")} onClick={() => deleteCategeory(row)}>delete</i>
    </div>;

    const formatUser = (cell, row) => {
        let username = users.filter(user => user.id === cell);

        return (username.length > 0) ? `${username[0].firstName} ${username[0].lastName}` : '-';
    }

    const formatImage = (cell, row) => 
        (catMedia[`${cell}`] !== undefined) ? 
        <img src={`${BaseURL()}pic/category/300x300/${catMedia[`${cell}`][0].filename}`} alt="..." style={{height:'24px'}} className="bg-danger" />: 
        '';

    const showCategorySub = (category) => 
            ReactDOM.render(<SubCategory subtitle={category.categoryName} users={users} categoryId={category.id} title={title} />, 
            document.getElementById("adminCon"));

    return (
        <div>
            <div className="adminHead d-flex flex-row justify-content-between">
                <span>{title}</span>
                <div className="d-flex flex-row">
                    <button className="btn btn-sm ml-2" onClick={() => showDialog({...Dto.category})}>{t("setup.new")} {t("setup.category")}</button>
                </div>
            </div>
            <div className="adminBody">
                <TableBase keys="id" data={categories} columns={columns} striped dense hover responsive />
            </div>

            <CategoryForm show={smShow} onHide={smClose} saveCategory={saveCategory} categoryItems={categoryItems} image={image} />
        </div>
    );
}

export default Category;