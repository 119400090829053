import React, { useState, useEffect } from "react";
import { StaticDialog } from "react-st-modal";
import Select from 'react-select';
import { DConnect, DecryptData, GetJCookie, GetUser, HandleError, InnerModal, PostOptions, showAlert } from "../../utils/MojaveLib";
import { useTranslation } from "react-i18next";
import moment from "moment";
import _ from "lodash";
import axios from 'axios'

function PromotionForm(props){

    const [category, setCategory] = useState(0);
    const [promoType, setPromoType] = useState(props.promoType)
    const [promoItems, setPromoItems] = useState(props.promoItems);

    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const [categories, setCategories] = useState([]);

    const {t, i18n} = useTranslation('translation');

    const dropdowns = GetJCookie('kyka-prepo');

    useEffect(() => {
        setPromoType(props.promoType);
        setPromoItems(props.promoItems);

        getInitData(props.promoItems);

    },[i18n, props.promoType, props.promoItems]);

    const getInitData = (promoItems) => {
        DConnect().get(`category`).then(res => {
            setCategories(res.data);
        }).catch(error =>HandleError(error));

        // alert(promoItems.productId)
        if(promoItems !== undefined && promoItems.id >0){
            axios(PostOptions("POST", {ids: promoItems.productId}, 'product/idin')).then(res => {
                // console.log(JSON.stringify(res.data.portals))
                let product = _.groupBy(res.data.products, 'portalId')
                let soption = res.data.portals.map(rdata => ({'label':rdata.title,'value':product[`${rdata.id}`][0].id}));

                // alert(JSON.)

                setSelectedOptions(soption)
            }).catch(err => console.log(err.message));
        }
    }

    const getProducts = (category) => {
        DConnect().get(`product/category/${category}`).then(res => {
            let options = [];

            res.data.map(rdata => {
                options.push({'value':rdata.productId, 'label':rdata.title});

                if(rdata.sku.trim() !== '' || rdata.model.trim() !== ''){
                    let modku = (rdata.sku.trim() !== '') ? rdata.sku.trim() : rdata.model.trim();
                    options.push({'value':rdata.productId, 'label':modku});
                }
            })

            // alert(JSON.stringify(options))
            setOptions(options);
        }).catch(error =>HandleError(error));
    }

    const handleCatChange = (e) => {
        e.preventDefault();

        const {name, value} = e.target;

        getProducts(value)

        setCategory(value);
    }

    const handleChange = (e) => {
        e.preventDefault();

        const {name, value} = e.target;

        setPromoItems(prevProps => ({...prevProps, [name]:value}));
    }

    const savePromotion = () => {
        let formData = {...promoItems};

        if(formData.promoName == ''){
            showAlert({ title: t("coupon.coupon"), message: `${t("coupon.coupon")} ${t("setup.name")} ${t("coupon.require")}`, btnText: 'Ok' })
            .then(res => props.setSmShow(true));
            // dialog.current.isOpen;
            return;
        }else if(formData.amount == 0){
            showAlert({ title: t("coupon.coupon"), message: `${t("coupon.amount")} ${t("coupon.require")}`, btnText: 'Ok' })
            .then(res => props.setSmShow(true));
            return;
        }else if(selectedOptions.length == 0 && promoType.id == 1){
            showAlert({ title: t("coupon.coupon"), message: `${t("portal.product")} ${t("coupon.require")}`, btnText: 'Ok' })
            .then(res => props.setSmShow(true));
            return;
        }

        if(promoType.id == 1){
            let productIds = selectedOptions.map(selopt => selopt.value);
            formData.productId = productIds.toString();
        }

        formData.promoType = promoType.id;
        formData.createdBy = GetUser().id;

        props.savePromotion(formData);

        setOptions([]);
        setCategory(0);
        setSelectedOptions([]);
    }

    return(
        <StaticDialog isOpen={props.show} isCanClose={false} title={<h5>{(promoItems.id > 0) ? t("setup.edit") : t("setup.new")} {t(`coupon.btn${promoType.id}`)} {t("welcome.promotion")}</h5>}
            onAfterClose={res => {
                props.onHide();

                if (res) { savePromotion(); }else{
                    setOptions([]);
                    setCategory(0);
                    setSelectedOptions([]);
                }
            }}>
            <InnerModal btns={[{ 'color': 'warning', 'label': t("setup.cancel") }, { 'color': 'success', 'label': `${t("setup.save")} ${t("welcome.promotion")}` }]}>
                <div className="row">
                    <div className="col-10">
                        <div className="form-group">
                            <label for="">{t("coupon.coupon")} {t("setup.name")}: </label>
                            <input type="text" id="promoName" name="promoName" className="form-control form-control-sm" 
                                value={promoItems.promoName} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label for="">{t("coupon.validity")}: </label>
                            <div className="input-group">
                                <input type="date" id="startDate" name="startDate" className="form-control form-control-sm" 
                                    value={moment(promoItems.startDate).format('YYYY-MM-DD')} onChange={handleChange} />
                                <span className="input-addon"> - </span>
                                <input type="date" id="endDate" name="endDate" className="form-control form-control-sm" 
                                    value={moment(promoItems.endDate).format('YYYY-MM-DD')} onChange={handleChange} />
                            </div>
                        </div>
                        {([1, 2, 3, 4].includes(parseInt(promoType.id))) && 
                        <div className="form-group">
                            <label for="">{t("coupon.limit")}: </label>
                            <div className="input-group">
                                <input type="number" id="lowLimit" name="lowLimit" className="form-control form-control-sm" 
                                    value={promoItems.lowLimit} onChange={handleChange} step='any' min={0} />
                                <span className="input-addon"> - </span>
                                <input type="number" id="highLimit" name="highLimit" className="form-control form-control-sm" 
                                    value={promoItems.highLimit} onChange={handleChange}  step='any' min={0} />
                            </div>
                        </div>}
                        <div className="form-group">
                            <label for="">{t("coupon.amount")}: </label>
                            <div className="input-group">
                                <input type="number" id="amount" name="amount" className="form-control form-control-sm" 
                                    value={promoItems.amount} onChange={handleChange} step='any' min={0} />
                                <span className="input-addon">&nbsp;</span>
                                <select id="amountType" name="amountType" className="form-control form-control-sm" 
                                    value={promoItems.amountType} onChange={handleChange}>
                                        <option value={1}>GHc</option>
                                        <option value={2}>%</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label for="">{t("coupon.usageType")}: </label>
                            <div className="input-group">
                                <select id="usageType" name="usageType" className="form-control form-control-sm" 
                                    value={promoItems.usageType} onChange={handleChange}>
                                        <option value={1}>Once</option>
                                        <option value={2}>Till Expiry</option>
                                </select>
                            </div>
                        </div>
                        {([1].includes(parseInt(promoType.id))) && 
                        <div className="form-group">
                            <label for="">{t("setup.category")}: </label>
                            <select id="category" name="category" className="form-control form-control-sm" 
                                value={promoItems.category} onChange={handleCatChange}>
                                    <option value="">-- Select category --</option>
                                    {categories.map(cat =>
                                    <option value={cat.id}>{cat.categoryName}</option>)}
                            </select>
                        </div>}
                        {([1].includes(parseInt(promoType.id))) && 
                        <div className="form-group">
                            <label for="">{t("portal.product")}: ({t("portal.sku")} / {t("setup.title")})</label>
                            <Select isClearable options={options} closeMenuOnSelect={false} isMulti 
                                onChange={(e) => setSelectedOptions(e)} value={selectedOptions} />
                        </div>}
                    </div>
                </div>
            </InnerModal>
        </StaticDialog>
    )
}

export default PromotionForm;
