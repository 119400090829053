import React, { useEffect, useState } from "react";
import { 
    DConnect, DecryptData, GetJCookie, GetUser, 
    HandleError, PostOptions, SetCookie,
    TableBase, showAlert 
} from "../../utils/MojaveLib";
import DropdownForm from "./DropdownForm";
import Dto from "../../utils/serializers";
import axios from "axios";
import _ from "lodash";
import { useTranslation } from 'react-i18next';

function Dropdown(props){

    const [title, setTitle] = useState(props.title);
    const [dropItems, setDropItems] = useState(Dto.prepopulate);
    const [dropdowns, setDropDowns] = useState(GetJCookie('kyka-prepo'));
    const [users, setUsers] = useState([]);
    const [smShow, setSmShow] = useState(false);
    const [type, setType] = useState('');
    const {t, i18n} = useTranslation('translation');

    
    const columns = [
        {name: t("setup.name"), selector: row => row.prename, sortable: true},
        {name: t("setup.value"), selector: row => row.prevalue, sortable: true},
        {name: t("setup.extravalue"), selector: row => row.prextra, sortable: true},
        {name: t("setup.lastmodify"), selector: row => formatUser(row.modifiedBy, row), sortable: true},
        {name: '', selector: row => formatAction(row.id, row), sortable: true},
    ];

    useEffect(() => {
        setTitle(props.title);

        getInitData();

    },[i18n, props.title, props.type]);

    const getInitData = () => {
        DConnect().get(`user`).then(res => {
            let users = DecryptData(res.data);

            setUsers(users);
        }).catch(error =>HandleError(error));
    }

    const getDropdown = () => {
        DConnect().get(`prepo`).then(res => {

            if(res.data.length > 0){
                setDropDowns(_.groupBy(res.data,'pretype'));
                SetCookie('kyka-prepo', JSON.stringify(_.groupBy(res.data,'pretype')));
                SetCookie('kyka-prepo_raw', JSON.stringify(res.data));
            }
        }).catch(error =>HandleError(error));
    }

    const showDialog = (dropItems) => {
        setDropItems(dropItems);

        setSmShow(true);
    }

    const smClose = () => setSmShow(false);

    const saveDropdown = (formData) => {

        let url =  `prepo`, protocol = 'POST';

        if(formData.id > 0){
            url = `prepo/${formData.id}`;
            protocol = 'PUT';
            formData.modifiedBy = GetUser().id;
        }else{
            formData.createdBy = GetUser().id;
            formData.displayType = formData.pretype
        }

        axios(PostOptions(protocol,formData, url)).then(res => {
            let resdata = res.data;

            getDropdown();

            showAlert({ title: t("setup.dropdown"), message: `${t("setup.dropdown")} ${t("setup.saved_success")}...`, btnText: t("setup.ok") });
        }).catch(error => { 
            showAlert({ title: t("setup.dropodwn"), message: `${t("setup.saved_error")} ${t("setup.dropdown")}...${error.message}`, btnText: t("setup.ok") });
        });
    }

    const formatAction = (cell, row) => <div style={{fontSize:'20px', lineHeight:'20px'}}>
        <i className='material-icons text-success' role="button" title={t("setup.edit")} onClick={() => showDialog(row)}>edit</i> &nbsp; | &nbsp; 
        <i className='material-icons text-danger' role="button" title={t("setup.delete")}>delete</i>
    </div>;

    const formatUser = (cell, row) => {
        let username = users.filter(user => user.id === cell);

        return (username.length > 0) ? `${username[0].firstName} ${username[0].lastName}` : '-';
    }

    return (
        <div>
            <div className="adminHead d-flex flex-row justify-content-between">
                <span>{title}</span>
                <div className="d-flex flex-row">
                    <select className="form-control form-control-sm" name="type" value={type} 
                        onChange={(e) => setType(e.target.value)}>
                        <option value=''>-- {t("setup.selectype")} --</option>
                        {Object.keys(dropdowns).map(drop => 
                            <option value={drop}>{drop.replace('_', ' ')}</option>)}
                    </select>
                    <button className="btn btn-sm ml-2" onClick={() => showDialog({...Dto.prepopulate})}>{t("setup.new")} {(type === '') ? t("setup.dropdown") : type.replace('_', ' ')}</button>
                </div>
            </div>
            <div className="adminBody">
                <TableBase keys="id" data={(dropdowns[type] === undefined) ? [] : dropdowns[type]} columns={columns} striped dense hover responsive />
            </div>

            <DropdownForm show={smShow} onHide={smClose} saveDropdown={saveDropdown} type={type} dropItems={dropItems} setType={setType} />
        </div>
    );
}

export default Dropdown;