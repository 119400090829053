import moment from 'moment';
import React from 'react';
import axios from 'axios';
import _ from 'lodash';
import CryptoJS from 'crypto-js';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import DataTable from 'react-data-table-component';

import { ModalContent, ModalFooter, ModalButton, useDialog, Confirm, Alert } from 'react-st-modal';

const Loader = () => <div className="page-loader-wrapper">
    <div className="loader">
        <div className="mt-3"><img className="zmdi-hc-spin w60" src="assets/images/loader.svg" alt="Kyka" /></div>
        <p>Please wait...</p>
    </div>
</div>;

const DoLogin = async (loginItems) => {

    await axios(PostOptions('POST',loginItems, 'user/authenticate')).then(res => {
        
        let resdata = DecryptData(res.data);

        if(resdata.user.status === 0){
            showAlert({ title: 'Login Error', message: `Your credentials are currently disabled, please contact Admin...`, btnText: 'OK' });

            return;
        }else if(resdata.user.roleId > 2){
            showAlert({ title: 'Login Error', message: `Your credentials are cannot login, please contact Admin...`, btnText: 'OK' });

            return;
        }

        SetToken(resdata.token);
        SetUser(resdata.user);
        // SetCookie('kyka-language', resdata.setup.defaultLang);
        SetCookie('kyka-role', JSON.stringify(resdata.role));
        SetCookie('kyka-setup', JSON.stringify(resdata.setup));
        SetFeatures(resdata.role.features);

        let grouped = _.groupBy(resdata.dropdowns, 'pretype'), result = {};

        Object.keys(grouped).map(pretype => {
            result[pretype] =  _.groupBy(grouped[pretype], 'lang');
        });

        SetCookie('kyka-prepo', JSON.stringify(grouped));
        SetCookie('kyka-prepo_new', JSON.stringify(result));
        SetCookie('kyka-prepo_raw', JSON.stringify(resdata.dropdowns));
        window.location.reload();
        
    }).catch(error => { 
        showAlert({ title: 'Login Error', message: `Login failed, check your credentials and try again...${error.message}`, btnText: 'OK' });
     });
}

const Logout = () => {
    sessionStorage.removeItem('kyka-token');
    sessionStorage.removeItem('kyka-user');
    sessionStorage.removeItem('kyka-features');
    sessionStorage.removeItem('kyka-prepo');
    sessionStorage.removeItem('kyka-prepo_raw');
    sessionStorage.removeItem('kyka-prepo_new');

    sessionStorage.removeItem('kyka-server');
    sessionStorage.removeItem('kyka-menu');
    
    // window.location.replace('http://192.168.2.101/kyker_backend');
    // window.location.replace('https://kykawholesale.com');
    // window.location.replace('http://localhost:3000');

    window.location.reload(true);
}

const EncryptData = (data) => {
    let keyHex = CryptoJS.enc.Base64.parse(PrivateKey());
    let encrypted = CryptoJS.AES.encrypt(data, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.toString();
}

const DecryptData = (data) => {
    let keyHex = CryptoJS.enc.Base64.parse(PrivateKey());
    let decrypted = CryptoJS.AES.decrypt({
        ciphertext: CryptoJS.enc.Base64.parse(data)
    }, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });

    let result = decrypted.toString(CryptoJS.enc.Utf8);

    try{
        return JSON.parse(result);
    }catch(error){
        return result;
    }
}

const PrivateKey = () => 'cdBUFttvQblmCfMmHwNPSJQnwqyr6Xhdk1YYlLvrh80=';

const SetUser = (user) => sessionStorage.setItem('kyka-user', JSON.stringify(user));
const GetUser = () => JSON.parse(sessionStorage.getItem('kyka-user'));

const SetToken = (token) => sessionStorage.setItem('kyka-token', token);
const GetToken = () => sessionStorage.getItem('kyka-token');

const GetAuthorization = () => (IsAuthenticated) ?
    { 'username': GetUser().username, 'token': GetToken() } :
    { 'username': '', 'token': '' };

const SetFeatures = (userole) => sessionStorage.setItem('features', JSON.stringify(userole));
const GetFeatures = () => JSON.parse(sessionStorage.getItem('features'));

const LengthFormat = (amount) => {
    const cf =  new Intl.NumberFormat(`en-GB`, 
        { maximumFractionDigits: 4, minimumFractionDigits: 4 });

    return cf.format(amount);
}

const CurFormat = (amount) => {
    const cf =  new Intl.NumberFormat(`en-GB`, 
        { maximumFractionDigits: 2, minimumFractionDigits: 2 });

    return cf.format(amount);
}

const NumFormat = (amount) => {
    const cf =  new Intl.NumberFormat(`en-GB`, 
        { maximumFractionDigits: 0, minimumFractionDigits: 0 });

    return cf.format(amount);
}

const DateFormat = (curDate, style) => { // style: full, long, medium, short
    const cf =  new Intl.DateTimeFormat(`en-GB`, 
        { dateStyle: style });

    return cf.format(curDate);
}

const Local = { format: "DD-MM-YYYY", sundayFirst: false };

const DateRange = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
};

const SetCookie = (name, value) => sessionStorage.setItem(name, value);
const GetCookie = (name) => sessionStorage.getItem(name);
const GetJCookie = (name) => JSON.parse(sessionStorage.getItem(name));

const IsAuthenticated = () => GetUser() !== null;

const HandleError = (error, func) => {
    if(func !== undefined){
        func(false);
    }

    if(error.response !== undefined && error.response.status === 401){
        showAlert({ title: "Error", message: `Your session has expired`, btnText: 'Ok' });
        Logout();
    }else{
        let msg = (error.response !== undefined && error.response.message !== undefined) ? error.response.message : error.message;
        showAlert({ title: "Error", message: msg, btnText: 'Ok' });
        console.log(error);
    }
}

const showAlert = async (props) => await Alert(props.message, props.title, props.btnText);
const showConfirm = async (props) => await Confirm( props.message, props.title, props.btnText[0], props.btnText[1]);

const MakeId = (length) => {
    var result = '';
    var characters = '0123456789';
    var charactersLength = characters.length;

    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

const DConnect = () => {
    // showAlert({ title: 'DConnect', message: `Bearer ${GetToken()}`, btnText: 'OK' });

    return axios.create({
        baseURL: `${BaseURL()}api/v1.0/`,
        timeout: 60000000,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${GetToken()}`
        }
    });
}

const PostOptions = (meth, formBody, area) => {
    // let formData = JSON.stringify(formBody);
    
    return {
        method: meth,
        url: `${BaseURL()}api/v1.0/${area}`,
        data: formBody,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'Authorization': `Bearer ${GetToken()}`
        }
    }
}

const PostImageOptions = (meth, formBody, area, {folder, filename}) => {
    const formData = new FormData(); 
    
    formData.append( "imagefile", formBody );
    formData.append( "foldername", folder );
    formData.append( "filename", filename );

    console.log(formData)
    
    return {
        method: meth,
        url: `${BaseURL()}api/v1.0/${area}`,
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data; charset=UTF-8',
            'Authorization': `Bearer ${GetToken()}`
        }
    }
}

const GetCurl = (location, itm) => {
    let loc = location.hash.split('/');
    return loc[itm];
};

const InnerModal = ({children, ...props}) => {
    const dialog = useDialog();

    return <div>
            <ModalContent> {children} </ModalContent>
            <ModalFooter>
                {props.btns.map( (b, idx) =>
                    <ModalButton  className={`pt-1 pb-1 pr-3 pl-3 bg-${b.color}`} onClick={() => { dialog.close(idx); }} >{b.label}</ModalButton>
                )}
            </ModalFooter>
        </div>
};

const GetInitials = (name) => {
    let initials = name.trim().split(' '),
        lname = initials.shift();


    return lname + '_' +(initials.map((n)=>n[0]).join('')).toUpperCase();
}

const TableBase = (props) => {

    const sortIcon = <i className='fa fa-arrow-down' />;
    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };
    const customStyles = {
        headRow:{
            style: {
                backgroundColor: '#4d2113',
                borderBottom: '2px solid #dee2e6',
                paddingTop:'0.75rem',
                paddingBottom:'0.75rem',
                borderTop: '1px solid #dee2e6',
                fontSize:'14px',
                borderTopLeftRadius:'10px',
                borderTopRightRadius:'10px'
            }
        },
        headCells: {
            style: {
                verticalAlign: 'middle',
                color: '#ECF0F1',
                // display: 'table-header-group',
            },
        },
        rows:{
            style:{
                // borderTop: '1px solid #dee2e6'
                fontSize:'14px',
                '&:hover:enabled': {
                    cursor: 'pointer',
                },
            },
            selectedHighlightStyle: {
                backgroundColor: 'rgb(26, 187, 156, .1)',
            },
            highlightOnHoverStyle: {
                backgroundColor: 'rgb(26, 187, 156, .1)',
            },
            stripedStyle: {
                backgroundColor: 'rgba(0,0,0,.05)',
            },
        },
        cells: {
            style: {
                minHeight:'44px'
            },
        },
        
    };

    // const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV({...props.data})} />, []);

    return(
        <DataTableExtensions
            {...props}
          print={false}
          export={false}
        >
        <DataTable //pagination dense striped responsive selectableRows 
            // selectableRowsComponent={<input type="checkbox" />}
            // selectableRowsComponentProps={selectProps}
            // actions={actionsMemo}
            // sortIcon={sortIcon}
            noHeader
            striped dense hover responsive pagination
            customStyles={customStyles}
            
        /></DataTableExtensions>
    );
}

// const Translate = async (text) => await translate(text, {to: 'DE'});

const BaseURL = () => {
    return 'https://kykawholesale.com/api/'; //Live server
    // return 'http://192.168.2.101:9095/'; //Office server
    // return 'http://localhost:9091/';
}

const MojaveLib = () => {}

MojaveLib.GetCurl = GetCurl;
MojaveLib.PostImageOptions = PostImageOptions;
MojaveLib.PostOptions = PostOptions;
MojaveLib.DConnect = DConnect;
MojaveLib.MakeId = MakeId;
MojaveLib.showAlert = showAlert;
MojaveLib.showConfirm = showConfirm;
MojaveLib.IsAuthenticated = IsAuthenticated;
MojaveLib.GetCookie = GetCookie;
MojaveLib.SetCookie = SetCookie;
MojaveLib.GetJCookie = GetJCookie;
MojaveLib.HandleError = HandleError;
MojaveLib.DateRange = DateRange;
MojaveLib.Local = Local;
MojaveLib.DateFormat = DateFormat;
MojaveLib.NumFormat = NumFormat;
MojaveLib.CurFormat = CurFormat;
MojaveLib.LengthFormat = LengthFormat;
MojaveLib.GetAuthorization = GetAuthorization;
MojaveLib.GetFeatures = GetFeatures;
MojaveLib.SetFeatures = SetFeatures;
MojaveLib.SetUser = SetUser;
MojaveLib.GetUser = GetUser;
MojaveLib.GetToken = GetToken;
MojaveLib.SetToken = SetToken;
MojaveLib.EncryptData = EncryptData;
MojaveLib.DecryptData = DecryptData;
MojaveLib.Logout = Logout;
MojaveLib.DoLogin = DoLogin;
MojaveLib.Loader = Loader;
MojaveLib.InnerModal = InnerModal;
MojaveLib.GetInitials = GetInitials;
MojaveLib.TableBase = TableBase;
MojaveLib.BaseURL = BaseURL;

export default MojaveLib;
export { 
    Loader, DoLogin, DecryptData, EncryptData, SetToken, GetToken, 
    GetUser, SetUser, SetFeatures, GetFeatures, GetAuthorization, LengthFormat, 
    CurFormat, NumFormat, DateFormat, Local, DateRange, HandleError, GetJCookie,
    SetCookie, GetCookie, IsAuthenticated, showConfirm, showAlert, MakeId,
    DConnect, PostOptions, PostImageOptions, GetCurl, InnerModal, GetInitials,
    TableBase, Logout, BaseURL
}