import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { DConnect, DecryptData, EncryptData, GetJCookie, GetUser, HandleError, PostOptions, TableBase, showAlert } from "../../utils/MojaveLib";
import { useTranslation } from "react-i18next";
import UserRoleForm from "./UserRoleForm";
import Dto from "../../utils/serializers";
import axios from "axios";
import Users from "./Users";

function UserRole(props){

    const [title, setTitle] = useState(props.title);
    const [roles, setRoles] = useState([]);
    const [roleItems, setRoleItems] = useState(Dto.roles);
    const [users, setUsers] = useState([]);
    const [smShow, setSmShow] = useState(false);

    const {t, i18n} = useTranslation('translation');

    const dropdowns = GetJCookie('kyka-prepo');

    const columns = [
        {name: t("setup.name"), selector: row => row.roleName, sortable: true},
        {name: t("setup.role_level"), selector: row => formatPrepo(row.userLevel, 'role_level'), sortable: true},
        {name: t("setup.status"), selector: row => formatPrepo(row.status, 'status'), sortable: true},
        {name: t("setup.lastmodify"), selector: row => row.modifiedBy, sortable: true},
        {name: '', selector: row => formatAction(row.id, row), sortable: true},
    ];

    useEffect(() => {
        setTitle(props.title);

        getInitData();
    },[props.title]);

    const getInitData = () => {
        DConnect().get(`user`).then(res => {
            let users = DecryptData(res.data);

            setUsers(users);
        }).catch(error =>HandleError(error));

        DConnect().get(`role`).then(res => {
            let roles = DecryptData(res.data);
            
            if(roles.length > 0){
                setRoles(roles);
            }
        }).catch(error =>HandleError(error));
    }

    const showDialog = (roleItems) => {
        setRoleItems(roleItems);

        setSmShow(true);
    }

    const smClose = () => setSmShow(false);

    const saveRole = (formData) => {
        let url =  `role`, protocol = 'POST';

        if(formData.id > 0){
            url = `role/${formData.id}`;
            protocol = 'PUT';
            formData.modifiedBy = GetUser().id;
        }else{
            formData.createdBy = GetUser().id;
        }

        axios(PostOptions(protocol,{'data':EncryptData(JSON.stringify(formData))}, url)).then(res => {
            let resdata = res.data;

            getInitData();

            showAlert({ title: t("setup.role"), message: `${t("setup.role")} ${t("setup.saved_success")}...`, btnText: t("setup.ok") });
        }).catch(error => { 
            showAlert({ title: t("setup.role"), message: `${t("setup.saved_error")} ${t("setup.role")}...${error.message}`, btnText: t("setup.ok") });
        });
    }

    const formatAction = (cell, row) => <div style={{fontSize:'20px', lineHeight:'20px'}}>
        <i className='material-icons text-success' role="button" title={t("setup.edit")} onClick={() => showDialog(row)}>edit</i> &nbsp; | &nbsp; 
        <i className='material-icons text-primary' role="button" title={t("setup.user")} onClick={() => showUsers(row)}>group</i> &nbsp; | &nbsp; 
        <i className='material-icons text-danger' role="button" title={t("setup.delete")}>delete</i>
    </div>;

    const formatPrepo = (cell, area) => {
        let proponame = dropdowns[area] !== undefined && dropdowns[area].filter(drop => drop.prevalue == cell);

        return proponame.length > 0 ? proponame[0].prename :  cell;
    }

    const showUsers = (role) => 
        ReactDOM.render(<Users subTitle={role.roleName} roleId={role.id} title={title} users={users} />, document.getElementById("adminCon"));

    return (
        <div>
            <div className="adminHead d-flex flex-row justify-content-between">
                <span>{title}</span>
                <div className="d-flex flex-row">
                    <button className="btn btn-sm ml-2" onClick={() => showDialog({...Dto.roles})}>{t("setup.new")} {t("setup.role")}</button>
                </div>
            </div>
            <div className="adminBody">
                <TableBase keys="id" data={roles} columns={columns} striped dense hover responsive />
            </div>

            <UserRoleForm show={smShow} onHide={smClose} saveRole={saveRole} roleItems={roleItems} />
        </div>
    );
}

export default UserRole;