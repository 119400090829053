import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GetJCookie } from "../../../utils/MojaveLib";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import $ from 'jquery';

function PortalInfo(props){

    const [portalItems, setPortalItems] = useState(props.portalItems);
    const [productItems, setProductItems] = useState(props.productItems);

    const [makes, setMakes] = useState(props.makes);

    const [categories, setCategories] = useState(props.categories);
    const [categorySubs, setCategorySubs] = useState(props.categorySubs);

    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const {t, i18n} = useTranslation('translation');

    const dropdowns = GetJCookie('kyka-prepo');

    useEffect(() => {
        
        setPortalItems(props.portalItems);
        setProductItems(props.productItems);

        setMakes(props.makes);

        setCategories(props.categories);
        setCategorySubs(props.categorySubs);

        if(props.selectedOptions !== undefined && props.selectedOptions.length > 0){
            setSelectedOptions(props.selectedOptions);

            // $('#categorySub').trigger("change");
        }


        initData();

    },[i18n, props.portalItems, props.productItems, props.makes, props.categories, props.categorySubs, props.selectedOptions]);

    const initData = () => {
        let options = dropdowns.color !== undefined && dropdowns.color.map(drop => ({ value: drop.prename, label: drop.prename }));
        
        // alert(JSON.stringify(options))
        setOptions(options);
    }

    const handleChange = (e) =>{
        // e.preventDefault();

        const {name, value} = e.target;

        if(['taxable'].includes(name)){
            setPortalItems(prevProps => ({...prevProps, [name]:e.target.checked}));
        }else{
            setPortalItems(prevProps => ({...prevProps, [name]:value}));
        }
    }

    const handleProductChange = (e) =>{
        // e.preventDefault();

        const {name, value} = e.target;

        if(['trackQty'].includes(name)){
            setProductItems(prevProps => ({...prevProps, [name]:e.target.checked}));
        }else{
            setProductItems(prevProps => ({...prevProps, [name]:value}));
        }
    }

    const submitForm = (e) => {
        e.preventDefault();

        props.handleChange(0,portalItems);

        let productData = {...productItems};
            //productData.colors = selectedOptions.map(sel => sel.value).toString();
        props.handleChange(1,productData);

        props.doNavigate(props,2);
    }

    return(
        <div>
            <h5 className="text-primary mt-2 mb-0">{t('product.pdetail')}</h5>
            <hr className="mt-0" />
            <form id="frmPortalData" onSubmit={submitForm}>
            <div className="row">
                <div className="col-xs-12 col-md-6 col-lg-5">
                    <div className="form-group">
                        <label for="">{t("product.category")}: </label>
                        <select id="category" name="category" className="form-control form-control-sm" 
                            value={productItems.category} onChange={handleProductChange} required >
                                <option value="">-- {t("setup.select")} {t("product.category")} --</option>
                                {categories.map(cat => 
                                    <option value={cat.id}>{cat.categoryName}</option>)}
                        </select>
                    </div>
                    <div className="form-group">
                        <label for="">{t("setup.make")}: </label>
                        <select id="make" name="make" className="form-control form-control-sm" 
                            value={productItems.make} onChange={handleProductChange} required >
                                <option value="">-- {t("setup.select")} {t("setup.make")} --</option>
                                {makes.map(make => 
                                    <option value={make.id}>{make.makeName}</option>)}
                        </select>
                    </div>
                    
                    <div className="form-group">
                        <label for="">{t("portal.uomeasure")}: </label>
                        <div className="input-group input-group-sm">
                            <input type="number" id="unitPcs" name="unitPcs" className="form-control form-control-sm text-right" 
                                value={productItems.unitPcs} onChange={handleProductChange} min={1} required /> 
                            <select id="unitOfMeasure" name="unitOfMeasure" className="form-control form-control-sm" 
                                value={productItems.unitOfMeasure} onChange={handleProductChange}>
                                    <option value="">-- {t("setup.select")} --</option>
                                    {dropdowns.unit_of_measure !== undefined && dropdowns.unit_of_measure.map(drop => 
                                        <option value={drop.prextra}>{drop.prename}</option>)}
                            </select>
                        </div>
                    </div>

                    <div className="form-group input-group input-group-sm pt-4">
                        <label className="mr-4" style={{lineHeight:'31px',whiteSpace:'nowrap'}}>
                            <input type="checkbox" id="taxable" name="taxable" className="form-control form-control-sm float-left" style={{width:'31px'}} 
                                checked={portalItems.taxable} onChange={handleChange} /> &nbsp; {t("portal.taxable")} </label>
                    </div>
                </div>
                <div className="col-xs-12 col-md-6 col-lg-5">
                    <div className="form-group">
                        <label for="">{t("product.catsub")}: </label>
                        <select id="categorySub" name="categorySub" className="form-control form-control-sm" 
                            value={productItems.categorySub} onChange={handleProductChange}>
                                <option value="">-- {t("setup.select")} {t("product.catsub")} --</option>
                                {categorySubs[productItems.category] !== undefined && categorySubs[productItems.category].map(subcat => 
                                    <option value={subcat.id}>{subcat.name}</option>)}
                        </select>
                    </div>
                    <div className="form-group">
                        <label for="">SKU {t("setup.number")}: </label>
                        <input type="text" id="sku" name="sku" className="form-control form-control-sm" 
                            value={productItems.sku} onChange={handleProductChange} /> 
                    </div>
                    <div className="form-group">
                        <label for="">{t("portal.shippedsource")}: </label>
                        <div className="input-group input-group-sm">
                            <input type="text" id="shippedSource" name="shippedSource" className="form-control form-control-sm" 
                                value={productItems.shippedSource} onChange={handleProductChange} /> 
                            <select id="freightType" name="freightType" className="form-control form-control-sm" 
                            value={productItems.freightType} onChange={handleProductChange}>
                                <option value="">-- {t("setup.select")} --</option>
                                {dropdowns.freight_type !== undefined && dropdowns.freight_type.map(drop => 
                                    <option value={drop.prextra}>{drop.prename}</option>)}
                        </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <label for="">{t("portal.dimension")}: </label>
                        <div className="input-group input-group-sm">
                            <span className="input-group-addon mr-2"> {t("portal.length")}: </span>
                            <input type="number" id="length" name="length" className="form-control form-control-sm mr-2" 
                                value={productItems.length} onChange={handleProductChange} min={0} step="any" />
                        
                            <span className="input-group-addon mr-2"> {t("portal.width")}: </span>
                            <input type="number" id="width" name="width" className="form-control form-control-sm mr-2" 
                                value={productItems.width} onChange={handleProductChange} min={0} step="any" />

                            <span className="input-group-addon mr-2"> {t("portal.height")}: </span>
                            <input type="number" id="height" name="height" className="form-control form-control-sm mr-2" 
                                value={productItems.height} onChange={handleProductChange} min={0} step="any" />

                            <span className="input-group-addon mr-2"> {t("portal.weight")}: </span>
                            <input type="number" id="weight" name="weight" className="form-control form-control-sm" 
                                value={productItems.weight} onChange={handleProductChange} min={0} step="any" />
                        </div>
                    </div>
                </div>
            </div>
            </form>
            <hr />
            <div className="d-flex flex-row justify-content-between">
                <div>&nbsp;</div>
                <button type="submit" form="frmPortalData" className="btn btn-bg btn-sm btn-outline-primary" style={{width:'150px'}}> {t("setup.next")} </button>
            </div>
        </div>
    )
}

export default PortalInfo;