import React, { useState, useEffect } from "react";
import { StaticDialog } from "react-st-modal";
import { GetJCookie, InnerModal } from "../../utils/MojaveLib";
import { useTranslation } from "react-i18next";

function PickupPointForm(props){

    const [pickupItem, setPickupItem] = useState(props.pickupPointItem);
    const [cities, setCities] = useState(props.cities);

    const {t, i18n} = useTranslation('translation');

    const dropdowns = GetJCookie('kyka-prepo');

    useEffect(() => {

        setPickupItem(props.pickupPointItem);
        setCities(props.cities);

    },[props.pickupPointItem, props.cities]);

    const handleChange = (e) => {
        e.preventDefault();

        const {name, value} = e.target;

        setPickupItem(prevProps => ({...prevProps, [name]:value}));
    }

    const savePickupPoint = () => props.savePickupPoint({...pickupItem});

    return (
        <StaticDialog isOpen={props.show} isCanClose={false} title={<h5>{(pickupItem.id > 0) ? t("setup.edit") : t("setup.new")} Pickup Point</h5>}
            onAfterClose={res => {
                props.onHide();


                if (res) { savePickupPoint(); }
            }}>
            <InnerModal btns={[{ 'color': 'warning', 'label': t("setup.cancel") }, { 'color': 'success', 'label': `${t("setup.save")} Pickup Point` }]}>
                <div className="row">
                    <div className="col-10">
                        <div className="form-group">
                            <label for="city">City: </label>
                            <select id="city" name="city" className="form-control form-control-sm" 
                                value={pickupItem.city} onChange={handleChange} >
                                    <option value="">-- Select city --</option>
                                    {cities.map(city => 
                                    <option value={city.id}>{city.cityName}</option>)}
                            </select>
                        </div>
                        <div className="form-group">
                            <label for="">Location: </label>
                            <input type="text" id="location" name="location" className="form-control form-control-sm" 
                                value={pickupItem.location} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label for="">GPS (Latitude/Longitude): </label>
                            <div className="input-group input-group-sm">
                                <input type="number" id="latitude" name="latitude" className="form-control form-control-sm" 
                                    value={pickupItem.latitude} onChange={handleChange} min={0} step="any" />
                                <input type="number" id="longitude" name="longitude" className="form-control form-control-sm" 
                                    value={pickupItem.longitude} onChange={handleChange} min={0} step="any" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label for="">Contact (Mobile/Phone): </label>
                            <div className="input-group input-group-sm">
                                <input type="text" id="mobile" name="mobile" className="form-control form-control-sm" 
                                    value={pickupItem.mobile} onChange={handleChange} />
                                <input type="text" id="phone" name="phone" className="form-control form-control-sm" 
                                    value={pickupItem.phone} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label for="">Email: </label>
                            <input type="text" id="email" name="email" className="form-control form-control-sm" 
                                value={pickupItem.email} onChange={handleChange} />
                        </div><div className="form-group">
                            <label for="">Contact Name: </label>
                            <input type="text" id="contactPerson" name="contactPerson" className="form-control form-control-sm" 
                                value={pickupItem.contactPerson} onChange={handleChange} />
                        </div>
                    </div>
                </div>
            </InnerModal>
        </StaticDialog>
    )
}

export default PickupPointForm;