import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from 'react-i18next';
import SubCategoryForm from "./SubCategoryForm";
import Dto from "../../utils/serializers";
import { DConnect, GetJCookie, GetUser, HandleError, PostOptions, TableBase, showAlert, showConfirm } from "../../utils/MojaveLib";
import axios from "axios";
import Category from "./Category";

function SubCategory(props){

    const [title, setTitle] = useState(props.title);
    const [subTitle, setSubTitle] = useState(props.subTitle);
    const [categoryId, setCategoryId] = useState(props.categoryId);
    const [catSubItems, setCatSubItems] = useState(Dto.categorySub);
    const [subCategories, setSubCategories] = useState([]);
    const [users, setUsers] = useState(props.users);
    const [smShow, setSmShow] = useState(false);

    const {t, i18n} = useTranslation('translation');

    const dropdowns = GetJCookie('kyka-prepo');

    const columns = [
        {name: `${t("setup.category")} ${t("setup.name")}`, selector: row => row.name, sortable: true},
        {name: t("setup.icon"), selector: row => <i className="material-icons">{row.icon}</i>, sortable: true},
        // {name: t("setup.lastmodify"), selector: row => formatUser(row.modifiedBy, row), sortable: true},
        {name: '', selector: row => formatAction(row.id, row), sortable: true},
    ];

    useEffect(() => {
        setUsers(props.users);
        setTitle(props.title);
        setSubTitle(props.subTitle);
        setCategoryId(props.categoryId);

        getInitData(props.categoryId);
    },[i18n, props.users, props.title, props.subTitle, props.categoryId]);

    const getInitData = (categoryId) => {
        if(categoryId === undefined || categoryId <= 0){
            return;
        }
        
        DConnect().get(`subcategory/?categoryId=${categoryId}`).then(res => {

            if(res.data.length > 0){
                setSubCategories(res.data);
            }
        }).catch(error =>HandleError(error));
    }

    const showDialog = (categoryItems) => {
        setCatSubItems(categoryItems);

        setSmShow(true);
    }

    const smClose = () => setSmShow(false);

    const saveSubCategory = (formData) => {
        let url =  `subcategory`, protocol = 'POST';

        if(formData.id > 0){
            url = `subcategory/${formData.id}`;
            protocol = 'PUT';
            formData.modifiedBy = GetUser().id;
        }else{
            formData.categoryId = categoryId;
            formData.createdBy = GetUser().id;
        }

        axios(PostOptions(protocol, formData, url)).then(res => {
            let resdata = res.data;

            getInitData(categoryId);

            showAlert({ title: t("setup.subcats"), message: `${t("setup.subcats")} ${t("setup.saved_success")}...`, btnText: t("setup.ok") });
        }).catch(error => { 
            showAlert({ title: t("setup.subcats"), message: `${t("setup.saved_error")} ${t("setup.subcats")}...${error.message}`, btnText: t("setup.ok") });
        });
    }

    const deleteCategorySub = (categorySub) => {
        showConfirm({title:`${t('setup.category')}`, message:`${t("portal.confirmImgDel")}...`, btnText:[`${t("setup.delete")}`,`${t("cancel")}`]}).then(res => {
            if(res){
                axios(PostOptions('DELETE', {}, `subcategory/${categorySub.id}`))
                .then(res => {
                    getInitData(categoryId);
                })
                .catch(err => console.log(err.message));
            }
        });
    }

    const formatAction = (cell, row) => <div style={{fontSize:'20px', lineHeight:'20px'}}>
        <i className='material-icons text-success' role="button" title={t("setup.edit")}  onClick={() => showDialog(row)}>edit</i> &nbsp; | &nbsp; 
        <i className='material-icons text-danger' role="button" title={t("setup.delete")} onClick={() => deleteCategorySub(row)}>delete</i>
    </div>;

    const goBack = () => ReactDOM.render(<Category title={title} />, document.getElementById("adminCon"));

    return(
        <div>
            <div className="adminHead d-flex flex-row justify-content-between">
                <span>{subTitle} {t("setup.subcats")}</span>
                <div className="d-flex flex-row">
                    <button className="btn btn-sm btn-warning ml-2" onClick={goBack}>{t("setup.back")}</button>
                    <button className="btn btn-sm ml-2" onClick={() => showDialog({...Dto.categorySub})}>{t("setup.new")} {t("setup.subcats")}</button>
                </div>
            </div>
            <div className="adminBody">
                <TableBase keys="id" data={subCategories} columns={columns} striped dense hover responsive pagination />
            </div>

            <SubCategoryForm show={smShow} onHide={smClose} saveSubCategory={saveSubCategory} catSubItems={catSubItems} />
        </div>
    );
}

export default SubCategory;