import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import $ from 'jquery';
import { BaseURL, GetJCookie, Loader, PostOptions, showAlert, showConfirm } from "../../../utils/MojaveLib";
import _ from "lodash";
import axios from 'axios';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';

function ProductImages(props){

    const ffmpeg = new FFmpeg({log: true});

    const [ready, isReady] = useState(false);
    const [video, setVideo] = useState();
    const [gif, setGif] = useState();

    const [imageItems, setImageItems] = useState(props.imageItems);
    const [dbImages, setDbImages] = useState(props.dbImages);
    const [product, setProduct] = useState(props.product);
    const [sortOrderEdit, setSortOrderEdit] = useState(props.sortOrderEdit);

    const {t, i18n} = useTranslation('translation');

    const dropdowns = GetJCookie('kyka-prepo');

    useEffect(() => {

        setSortOrderEdit(props.sortOrderEdit);
        setImageItems(props.imageItems);
        setDbImages(props.dbImages);
        setProduct(props.product);

        // load();
    },[props.imageItems, props.dbImages,  props.product, props.sortOrderEdit]);

    const load = async () => {
        await ffmpeg.load();
        
        isReady(true);
    }

    const handleNewImageOrder = (e, idx) => {
        e.preventDefault();

        const {name, value} = e.target;

        let curImageItems = [...imageItems];
            curImageItems[idx]['sortOrder'] = value;

        setImageItems(_.orderBy(curImageItems, 'sortOrder'));
    }

    const handleImageReOrder = (e, itm) => {
        e.preventDefault();

        const {name, value} = e.target;

        let curSortOrder = [...dbImages],
            idx = _.findIndex(curSortOrder, (img) => img.id === itm);
        curSortOrder[idx]['sortOrder'] = value;

        setDbImages(dbImages);
        setSortOrderEdit(prevProps => ({...prevProps, [`${itm}`]:value}));
    }

    const imageChange = (e) => {
        e.preventDefault();

        let curImageItems = [...imageItems],
            newImageItems = [],
            files = Array.from(e.target.files),
            ext = [];

            // alert(files.length);
        files.map( (img) => {
            let reader = new FileReader();

            ext = img.name.split('.');
            ext = ext[ext.length - 1].toLowerCase();

            if (!['jpg', 'jpeg', 'png', 'mp4'].includes(ext)) {//, 'mp4'
                showAlert({ title: `${t("portal.supprtimgformat")}`, message: `${t("portal.msgformat")}`, btnText: 'Ok' });
                return;
            }

            reader.onloadend = () => {
                let sortOrder = dbImages.length + imageItems.length + 1,
                    curImage = { 'image': img, 'file': reader.result, 'ext': ext, 'sortOrder': sortOrder};

                newImageItems.push(curImage);

                if(newImageItems.length == files.length){
                    setImageItems([...curImageItems, ...newImageItems]);
                }
            }

            reader.readAsDataURL(img);
        });
    }

    const removeImage = (idx) => {
        let curImageItems = [...imageItems];

        curImageItems.splice(idx, 1);

        setImageItems(curImageItems);
    }

    const deleteImage = (image) => {
        showConfirm({title:`${t('portal.images')}`, message:`${t("portal.confirmImgDel")}...`, btnText:[`${t("setup.delete")}`,`${t("cancel")}`]}).then(res => {
            if(res){
                let extImages = [...dbImages],
                    idx = _.findIndex(extImages, image);

                if(idx > -1){
                    extImages.splice(idx, 1);

                    setDbImages(extImages);

                    axios(PostOptions('DELETE', image, `media/${image.id}`))
                    .catch(error => alert(error.message));
                }
            }
        });

    }

    const submitForm = () => {

        props.handleChange(2,imageItems);

        props.handleChange(4, sortOrderEdit);

        props.handleChange(5, dbImages);

        props.doNavigate(props,5);
    }

    // if(!ready){
    //     return <Loader />;
    // }

    return(
        <div>
            <h5 className="text-primary mt-2 mb-0 d-flex flex-row justify-content-between">
                <span>{t("portal.productimages")} </span>
                <button className="btn btn-sm btn-primary" onClick={() => $('#productImage').click()}>{t("setup.new")} {t("portal.image")} (jpg, jpeg, png, mp4)</button>
                <input type="file" multiple name="productImage" style={{ display: 'none' }} id="productImage" onChange={imageChange} />
            </h5>
            <hr className="mt-0" />
            <small className="text-danger">{t("portal.imgdel")}</small>
           <div className="d-flex flex-row flex-wrap">
                {(_.orderBy(dbImages, 'sortOrder')).map((img, idx) => img.mediaType == 2 ?  <div className="mb-3">
                        <div className="idImg bg-g10 " role='button' style={{overflowX:'hidden', marginRight:'10px', height: '240px'}}>
                            <video height="240" controls>
                                <source src={`${BaseURL()}pic/${product.category}/${img.filename}`} type="video/mp4" />
                                Your browser does not support the video tag
                            </video>
                        </div>
                        <div className="mr-3 text-center">
                            <button className="btn btn-sm btn-danger" style={{height:'31px'}} onClick={() => deleteImage(img)}>{t("setup.delete")}</button>
                        </div>
                    </div>
                : <div className="mb-3">
                    <div className="idImg bg-g10" role='button' style={{overflow:'hidden', marginRight:'10px', height: '240px'}}>
                        <img src={`${BaseURL()}pic/${product.category}/${img.filename}`} style={{height:'240px', cursor:"pointer" }} alt="Product Image" onDoubleClick={() => deleteImage(img)} />
                    </div>
                    <div><select className="form-control form-control-sm" id="" name="" onChange={(e) => handleImageReOrder(e, img.id)} value={img.sortOrder}>
                            {[...Array(20)].map((e, i) => 
                            <option value={i}>{i}</option>)}
                    </select></div>
                </div>)}
                {_.orderBy(imageItems, 'sortOrder').map((img, idx) => img.ext === 'mp4' ?  <div className="mb-3">
                        <div className="idImg bg-g10 " role='button' style={{overflowX:'hidden', marginRight:'10px', height: '240px'}}>
                            {/* <img src={img.file} style={{height:'240px', cursor:"pointer" }} alt="Product Image" onDoubleClick={() => removeImage(idx)} /> */}
                            <video height="240" controls>
                                <source src={img.file} type="video/mp4" />
                                Your browser does not support the video tag
                            </video>
                        </div>
                        <div className="mr-3 text-center">
                            <button className="btn btn-sm btn-danger" style={{height:'31px'}} onClick={() => removeImage(idx)}>{t("setup.delete")}</button>
                        </div>
                    </div>
                : <div className="mb-3">
                    <div className="idImg bg-g10 " role='button' style={{overflowX:'hidden', marginRight:'10px', height: '240px'}}>
                        <img src={img.file} style={{height:'240px', cursor:"pointer" }} alt="Product Image" onDoubleClick={() => removeImage(idx)} />
                    </div>
                    <div className="mr-3">
                        <select className="form-control form-control-sm" id="sortOrder" name="sortOrder" 
                            onChange={(e) => handleNewImageOrder(e, idx)} value={img.sortOrder}>
                            {[...Array(20)].map((e, i) => 
                            <option value={i}>{i}</option>)}
                        </select>
                    </div>
                </div>)}
                
            </div>
            <hr />
            <div className="d-flex flex-row justify-content-between">
                <button className="btn btn-bg btn-sm btn-outline-primary" onClick={() => props.doNavigate(props,3)}>{t("setup.previous")} </button>
                <button className="btn btn-bg btn-sm btn-outline-primary" style={{width:'150px'}} onClick={submitForm}> {t("portal.preview")}</button>
            </div>
        </div>
    )
}

export default ProductImages;