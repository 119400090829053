import React, { useState, useEffect } from "react";
import Dto from "../../../utils/serializers";
import { useTranslation } from "react-i18next";
import { GetJCookie, InnerModal, PostOptions, TableBase, showAlert } from "../../../utils/MojaveLib";
import { StaticDialog } from "react-st-modal";
import _ from "lodash";
import axios from "axios";
import { ActionMeta, OnChangeValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';

function ProductPrices(props){

    const [productItems, setProductItems] = useState(props.productItems);
    const [productExtraItems, setProductExtraItems] = useState(props.productExtraItems);
    const [productExtras, setProductExtras] = useState({});
    const [priceItem, setPriceItem] = useState(Dto.prices);
    const [selectedTypes, setSelectedTypes] = useState([]);

    const [options, setOptions] = useState([{'label':'','value':''}]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [options1, setOptions1] = useState([{'label':'','value':''}]);
    const [selectedOptions1, setSelectedOptions1] = useState([]);

    // const [smShow, setSmShow] = useState(false);
    const [idx, setIdx] = useState(-1);

    const {t, i18n} = useTranslation('translation');

    const dropdowns = GetJCookie('kyka-prepo');
    const customStyles={
          control: (provided)=>({
            ...provided,
            width:'350px'
         })
    }

    const columns = [
        {name: `${t("portal.min")} ${t("portal.quantity")}`, selector: row => formatQuantity(row.minQty, row), sortable: true},
        {name: `${t("portal.uprice")}`, selector: row => row.amount, sortable: true},
        {name: `${t("portal.discountrate")}`, selector: row => row.discountRate, sortable: true},
        {name: '', selector: row => formatAction(row.id, row), sortable: true},
    ];

    useEffect(() => {
        setProductItems(props.productItems);
        setProductExtraItems(props.productExtraItems);

        getInitData(props.productItems, props.productExtraItems);
    },[props.productExtraItems, props.productItems]);

    const getInitData = (productItems, productExtraItems) => {
        
        if(productExtraItems === undefined || productItems === undefined){
            return;
        }

        if(productExtraItems.length === 0){
            productExtraItems.push(Dto.productExtra);
        }

        let pextras = {}, 
            selType = (productItems.extraType0 > 0) ? [productItems.extraType0] : [],
            selType1 = (productItems.extraType1 > 0) ? [productItems.extraType1] : [],
            selOpt = productItems.typeName0 !== '' ? productItems.typeName0.split(',').map(opt => ({'label':opt, 'value':opt})) : [],
            selOpt1 =  productItems.typeName1 !== '' ? productItems.typeName1.split(',').map(opt => ({'label':opt, 'value':opt})) : [];

        setSelectedTypes([...selType, ...selType1]);

        if(selType.length > 0){
            setSelectedOptions([...[{'label':'','value':''}],...selOpt]);
        }else{
            setSelectedOptions([{'label':'','value':''}]);
        }

        if(selOpt1.length > 0){
            setSelectedOptions1(selOpt1);
        }

        let extraExist = _.groupBy(productExtraItems, 'extraType');
        selOpt.map(opt => {
            

            if(extraExist[opt.value] === undefined){
                let newExtra = {...extraExist[''][0]};
                    newExtra.extraType = opt.value;
                    newExtra.id = 0;

                productExtraItems.push(newExtra);
            }
        });

        productExtraItems.map(pextra => {
            pextras[`${pextra.extraType}`] = pextra;
        });

        setProductExtras(pextras);
        setProductExtraItems(productExtraItems);
    }

    const handleExtraChange = (e, area) => {
        e.preventDefault();

        const {name, value} = e.target;

        let pextras = {...productExtras};

        if(pextras[area] === undefined){
            pextras[area] = {...Dto.productExtra};
        }

        pextras[area][name] = value;

        setProductExtras(pextras);
    }

    const handleExtraType = (e) => {
        let newSelectedTypes = [...selectedTypes];

        const {name, value} = e.target;

        if(e.target.checked){
            let isZero = newSelectedTypes.includes(0);
            
            if((isZero && newSelectedTypes.length == 3) || (!isZero && newSelectedTypes.length == 2)){
                return;
            }

            newSelectedTypes.push(parseInt(value));
        }else{
            let idx = newSelectedTypes.indexOf(parseInt(value));

            if(idx >=0){
                newSelectedTypes.splice(idx, 1);
                
                if(idx === 0){
                    setSelectedOptions([{'label':'','value':''}]);
                }else{
                    setSelectedOptions1([])
                }
            }
        }

        setSelectedTypes(newSelectedTypes);
    }

    const deletePrice = (price) => {
        // let newPrices = [...priceItems],
        //     idx = _.findIndex(newPrices, price);

        //     newPrices.splice(idx,1);

        // setPriceItems(newPrices);

        // if(price.id > 0){
        //     axios(PostOptions('DELETE', price, `price/${price.id}`));
        // }
    }

    const formatQuantity = (cell, row) => `${cell} - ${row.maxQty}`;

    const formatPrepo = (cell, area) => {
        let preponame = dropdowns[area] !== undefined && dropdowns[area].filter(drop  => drop.prevalue == cell);

        return (preponame.length>0) ? preponame[0].prename : cell;
    }

    const formatAction = (cell, row) => <div style={{fontSize:'20px', lineHeight:'20px'}}>
        {/* <i className='material-icons text-success' role="button" title={t("setup.edit")} onClick={() => showDialog(row)}>edit</i> &nbsp; | &nbsp;  */}
        <i className='material-icons text-danger' role="button" title={t("setup.delete")} onClick={() => deletePrice(row)}>delete</i>
    </div>;

    const savePrice = (itm) => {

        let pextras = [], productData = {...productItems},
            typeName0 = selectedOptions.map(opt => opt.value),
            typeName1 = selectedOptions1.map(opt => opt.value);

        typeName0.shift();
        // typeName1.shift();
        // alert(typeName1)

        productData.extraType0 = selectedTypes[0];
        productData.extraType1 = selectedTypes[1];
        productData.typeName0 = typeName0.toString();
        productData.typeName1 = typeName1.toString();

        props.handleChange(1,productData);

        Object.keys(productExtras).map((key, idx) => {
            let pextra = {...productExtras[key]};
                pextra.extraType = key;

            pextras.push(pextra);
        });

        setProductExtraItems(pextras);
        props.handleChange(6, pextras);

        props.doNavigate(props,itm);
    }

    const onChange = ( newValue, actionMeta ) => {
        switch (actionMeta.action) {
          case 'remove-value':
          case 'pop-value':
            // alert(actionMeta.removedValue.label)
            if (actionMeta.removedValue.label === '') {
                showAlert({ title: t("portal.product"), message: `Cannot be removed...`, btnText: t("setup.ok") });
                return;
            }
            break;
          case 'clear':
            newValue = options[0];
            break;
        }
    
        setSelectedOptions(newValue);
    };

    const onChange1 = ( newValue, actionMeta ) => {
        switch (actionMeta.action) {
          case 'remove-value':
          case 'pop-value':
            // alert(actionMeta.removedValue.label)
            if (actionMeta.removedValue.label === '') {
                showAlert({ title: t("portal.product"), message: `Cannot be removed...`, btnText: t("setup.ok") });
                return;
            }
            break;
          case 'clear':
            newValue = options[0];
            break;
        }
    
        setSelectedOptions1(newValue);
    };

    return(
        <div>
            <h5 className="text-primary mt-2 mb-0 d-flex flex-row justify-content-between">
                <span>{t("portal.price")} {t("setup.detail")}</span>
                {/* <button className="btn btn-sm btn-primary" onClick={() => showDialog({...Dto.prices})}>{t("setup.new")} {t("portal.price")}</button> */}
            </h5>
            <hr className="mt-0" />

            <div className="border border-primary pt-2 pl-2 pr-2">
                <div className="d-flex flex-row">
                    {dropdowns.product_extra_type !== undefined && dropdowns.product_extra_type.map( etype => 
                    <label className="mr-4" style={{lineHeight:'31px',whiteSpace:'nowrap'}}>
                        <input type="checkbox" id="extraType" name="extraType" className="form-control form-control-sm float-left" style={{width:'31px'}} 
                            value={parseInt(etype.prevalue)} onChange={handleExtraType} checked={selectedTypes.includes(parseInt(etype.prevalue))} /> 
                            &nbsp; {etype.prename} </label>)}
                </div>
                <div className="d-flex flex-row">
                    {selectedTypes.map((stype, idx) => stype > 0 &&
                    <div className="form-group mr-4">
                        <label for="">{formatPrepo(stype, 'product_extra_type')}: </label>
                        <CreatableSelect isClearable closeMenuOnSelect={false} isMulti 
                            options={(idx === 0) ? options : options1} 
                            onChange={(idx === 0) ? onChange : onChange1} 
                            value={(idx === 0) ? selectedOptions : selectedOptions1} 
                            styles={customStyles}  /> 
                    </div>)}
                </div>
            </div>

            <div className="">
                {
                <table className="table table-condensed table-striped" style={{fontSize: '13px'}}>
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Stock Qty</th>
                            <th>Min Qty / Price / Discount Rate 1</th>
                            <th>Min Qty / Price / Discount Rate 2</th>
                            <th>Min Qty / Price / Discount Rate 3</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedOptions.map( opt => selectedOptions1.length > 0 ?
                        selectedOptions1.map(opt1 =>
                        <tr>
                            <td>{(opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`}</td>
                            <td><input type="number" name="quantity" value={(productExtras[(opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`])? productExtras[(opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`].quantity: productExtras[``].quantity} onChange={(e) => handleExtraChange(e, (opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`)} className="text-right" style={{width: '80px'}} /></td>
                            <td>
                                <input type="number" name="qty1" value={(productExtras[(opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`])? productExtras[(opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`].qty1: productExtras[``].qty1} onChange={(e) => handleExtraChange(e, (opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`)} className="text-right" style={{width: '80px'}} /> /&nbsp;
                                <input type="number" name="amount1" value={(productExtras[(opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`])? productExtras[(opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`].amount1: productExtras[``].amount1} onChange={(e) => handleExtraChange(e, (opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`)} className="text-right" style={{width: '80px'}} /> /&nbsp; 
                                <input type="number" name="discount1" value={(productExtras[(opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`])? productExtras[(opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`].discount1: productExtras[``].discount1} onChange={(e) => handleExtraChange(e, (opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`)} className="text-right" style={{width: '80px'}} />
                            </td>
                            <td>
                                <input type="number" name="qty2" value={(productExtras[(opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`])? productExtras[(opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`].qty2: productExtras[``].qty2} onChange={(e) => handleExtraChange(e, (opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`)} className="text-right" style={{width: '80px'}} /> /&nbsp;
                                <input type="number" name="amount2" value={(productExtras[(opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`])? productExtras[(opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`].amount2: productExtras[``].amount2} onChange={(e) => handleExtraChange(e, (opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`)} className="text-right" style={{width: '80px'}} /> /&nbsp; 
                                <input type="number" name="discount2" value={(productExtras[(opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`])? productExtras[(opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`].discount2: productExtras[``].discount2} onChange={(e) => handleExtraChange(e,(opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`)} className="text-right" style={{width: '80px'}} />
                            </td>
                            <td>
                                <input type="number" name="qty3" value={(productExtras[(opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`])? productExtras[(opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`].qty3: productExtras[``].qty3} onChange={(e) => handleExtraChange(e, (opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`)} className="text-right" style={{width: '80px'}} /> /&nbsp;
                                <input type="number" name="amount3" value={(productExtras[(opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`])? productExtras[(opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`].amount3: productExtras[``].amount3} onChange={(e) => handleExtraChange(e, (opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`)} className="text-right" style={{width: '80px'}} /> /&nbsp; 
                                <input type="number" name="discount3" value={(productExtras[(opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`])? productExtras[(opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`].discount3: productExtras[``].discount3} onChange={(e) => handleExtraChange(e, (opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`)} className="text-right" style={{width: '80px'}} />
                            </td>
                            <td>{formatAction((opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`, (productExtras[(opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`])? productExtras[(opt.label === '') ? `${opt.label}` : `${opt.label} : ${opt1.label}`]:'')}</td>
                        </tr>) : <tr>
                            <td>{opt.label}</td>
                            <td><input type="number" name="quantity" value={(productExtras[`${opt.value}`])? productExtras[`${opt.value}`].quantity: productExtras[``].quantity} onChange={(e) => handleExtraChange(e, `${opt.label}`)} className="text-right" style={{width: '80px'}} /></td>
                            <td>
                                <input type="number" name="qty1" value={(productExtras[`${opt.value}`])? productExtras[`${opt.value}`].qty1: productExtras[``].qty1} onChange={(e) => handleExtraChange(e, `${opt.label}`)} className="text-right" style={{width: '80px'}} /> /&nbsp;
                                <input type="number" name="amount1" value={(productExtras[`${opt.value}`])? productExtras[`${opt.value}`].amount1: productExtras[``].amount1} onChange={(e) => handleExtraChange(e, `${opt.label}`)} className="text-right" style={{width: '80px'}} /> /&nbsp; 
                                <input type="number" name="discount1" value={(productExtras[`${opt.value}`])? productExtras[`${opt.value}`].discount1: productExtras[``].discount1} onChange={(e) => handleExtraChange(e, `${opt.label}`)} className="text-right" style={{width: '80px'}} />
                            </td>
                            <td>
                                <input type="number" name="qty2" value={(productExtras[`${opt.value}`])? productExtras[`${opt.value}`].qty2: productExtras[``].qty2} onChange={(e) => handleExtraChange(e, `${opt.label}`)} className="text-right" style={{width: '80px'}} /> /&nbsp;
                                <input type="number" name="amount2" value={(productExtras[`${opt.value}`])? productExtras[`${opt.value}`].amount2: productExtras[``].amount2} onChange={(e) => handleExtraChange(e, `${opt.label}`)} className="text-right" style={{width: '80px'}} /> /&nbsp; 
                                <input type="number" name="discount2" value={(productExtras[`${opt.value}`])? productExtras[`${opt.value}`].discount2: productExtras[``].discount2} onChange={(e) => handleExtraChange(e, `${opt.label}`)} className="text-right" style={{width: '80px'}} />
                            </td>
                            <td>
                                <input type="number" name="qty3" value={(productExtras[`${opt.value}`])? productExtras[`${opt.value}`].qty3: productExtras[``].qty3} onChange={(e) => handleExtraChange(e, `${opt.label}`)} className="text-right" style={{width: '80px'}} /> /&nbsp;
                                <input type="number" name="amount3" value={(productExtras[`${opt.value}`])? productExtras[`${opt.value}`].amount3: productExtras[``].amount3} onChange={(e) => handleExtraChange(e, `${opt.label}`)} className="text-right" style={{width: '80px'}} /> /&nbsp; 
                                <input type="number" name="discount3" value={(productExtras[`${opt.value}`])? productExtras[`${opt.value}`].discount3: productExtras[``].discount3} onChange={(e) => handleExtraChange(e, `${opt.label}`)} className="text-right" style={{width: '80px'}} />
                            </td>
                            <td>{formatAction(`${opt.value}`, (productExtras[`${opt.value}`])? productExtras[`${opt.value}`]:'')}</td>
                        </tr>)}
                    </tbody>
                </table>}
            </div>

            {/* <TableBase keys="id" data={priceItems} columns={columns} striped dense hover responsive /> */}
            <hr />
            <div className="d-flex flex-row justify-content-between">
                <button className="btn btn-bg btn-sm btn-outline-primary" onClick={() => savePrice(2)}>{t("setup.previous")} </button>
                <button className="btn btn-bg btn-sm btn-outline-primary" style={{width:'150px'}} onClick={() => savePrice(4)}> {t("setup.next")} </button>
            </div>

            {/* <StaticDialog isOpen={smShow} isCanClose={false} title={<h5>{(priceItem.id > 0) ? t("setup.edit") : t("setup.new")} {t("portal.price")}</h5>}
                onAfterClose={res => {
                    smClose();


                    if (res) { savePrice(); }
                }}>
                <InnerModal btns={[{ 'color': 'warning', 'label': t("setup.cancel") }, { 'color': 'success', 'label': `${t("setup.save")} ${t('portal.price')}` }]}>
                    <div className="row">
                        <div className="col-10">
                            <div className="form-group">
                                <label for="">{t("portal.min")} {t("portal.quantity")}: </label>
                                <input type="number" id="minQty" name="minQty" className="form-control form-control-sm text-right" 
                                    value={priceItem.minQty} onChange={handleChange} min={1} required /> 
                            </div>
                            <div className="form-group">
                                <label for="">{t("portal.max")} {t("portal.quantity")}: </label>
                                <input type="number" id="maxQty" name="maxQty" className="form-control form-control-sm text-right" 
                                    value={priceItem.maxQty} onChange={handleChange} min={1} required /> 
                            </div>
                            <div className="form-group">
                                <label for="">{t("portal.uprice")}: </label>
                                <input type="number" id="amount" name="amount" className="form-control form-control-sm text-right" 
                                    value={priceItem.amount} onChange={handleChange} min={1} step={'any'} required /> 
                            </div>
                            <div className="form-group">
                                <label for="">{t("portal.discountrate")}: </label>
                                <input type="number" id="discountRate" name="discountRate" className="form-control form-control-sm text-right" 
                                    value={priceItem.discountRate} onChange={handleChange} min={1} step={'any'} required /> 
                            </div>
                        </div>
                    </div>
                </InnerModal>
            </StaticDialog> */}
        </div>
    )
}

export default ProductPrices;