import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import PortalForm from "./PortalForm";
import Dto from "../../utils/serializers";
import { DConnect, DecryptData, GetJCookie, GetUser, HandleError, PostOptions, TableBase, showAlert } from "../../utils/MojaveLib";
import { useTranslation } from "react-i18next";
import axios from "axios";

function Portal(props){

    const [title, setTitle] = useState(props.title);
    const [portals, setPortals] = useState([]);
    const [portalItems, setPortalItems] = useState(Dto.portals);
    const [users, setUsers] = useState([]);
    const [smShow, setSmShow] = useState(false);

    const {t, i18n} = useTranslation('translation');

    const dropdowns = GetJCookie('kyka-prepo');

    const columns = [
        {name: t("setup.page"), selector: row => formatPrepo(row.page, 'page'), sortable: true},
        {name: t("setup.pagetype"), selector: row => row.pageType, sortable: true},
        {name: t("setup.title"), selector: row => row.title, sortable: true},
        {name: t("setup.lastmodify"), selector: row => row.modifiedBy, sortable: true},
        {name: t("setup.status"), selector: row => formatPrepo(row.status, 'status'), sortable: true},
        {name: '', selector: row => formatAction(row.id, row), sortable: true},
    ];

    useEffect(() => {
        setTitle(props.title);

        getInitData();
    },[props.title]);

    const getInitData = () => {
        DConnect().get(`user`).then(res => {
            let users = DecryptData(res.data);

            setUsers(users);
        }).catch(error =>HandleError(error));

        DConnect().get(`portal`).then(res => {
            
            if(res.data.length > 0){
                let resdata = res.data.filter(rdata => rdata.page < 3)
                setPortals(resdata);
            }
        }).catch(error =>HandleError(error));
    }

    const showDialog = (portalItems) => {
        setPortalItems(portalItems);

        setSmShow(true);
    }

    const smClose = () => setSmShow(false);

    const savePortal = (formData) => {
        let url =  `portal`, protocol = 'POST';

        if(formData.id > 0){
            url = `portal/${formData.id}`;
            protocol = 'PUT';
            formData.modifiedBy = GetUser().id;
        }else{
            formData.createdBy = GetUser().id;
        }

        axios(PostOptions(protocol,formData, url)).then(res => {
            let resdata = res.data;

            getInitData();

            showAlert({ title: t("setup.portal"), message: `${t("setup.portal")} ${t("setup.saved_success")}...`, btnText: t("setup.ok") });
        }).catch(error => { 
            showAlert({ title: t("setup.portal"), message: `${t("setup.saved_error")} ${t("setup.portal")}...${error.message}`, btnText: t("setup.ok") });
        });
    }

    const formatAction = (cell, row) => <div style={{fontSize:'20px', lineHeight:'20px'}}>
        <i className='material-icons text-success' role="button" title={t("setup.edit")} onClick={() => showDialog(row)}>edit</i> &nbsp; | &nbsp; 
        <i className='material-icons text-danger' role="button" title={t("setup.delete")}>delete</i>
    </div>;

    const formatPrepo = (cell, area) => {
        let proponame = dropdowns[area] !== undefined && dropdowns[area].filter(drop => drop.prevalue == cell);

        return proponame.length > 0 ? proponame[0].prename :  cell;
    }

    return (
        <div>
            <div className="adminHead d-flex flex-row justify-content-between">
                <span>{title}</span>
                <div className="d-flex flex-row">
                    <button className="btn btn-sm ml-2" onClick={() => showDialog({...Dto.portals})}>{t("setup.new")} {t("setup.portal")}</button>
                </div>
            </div>
            <div className="adminBody">
                <TableBase keys="id" data={portals} columns={columns} striped dense hover responsive />
            </div>

            <PortalForm show={smShow} onHide={smClose} savePortal={savePortal} portalItems={portalItems} />
        </div>
    );
}

export default Portal;