import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetJCookie, InnerModal } from "../../utils/MojaveLib";
import { StaticDialog } from "react-st-modal";

function MakeForm(props){

    const [makeItems, setMakeItems] = useState(props.makeItems);

    const {t, i18n} = useTranslation('translation');

    useEffect(() => {
        setMakeItems(props.makeItems);

    },[i18n, props.makeItems]);

    const handleChange = (e) => {
        e.preventDefault();

        const {name, value} = e.target;

        setMakeItems(prevProps => ({...prevProps, [name]:value}));
    }

    const saveMake = () => props.saveMake(makeItems);

    return(
        <StaticDialog isOpen={props.show} isCanClose={false} title={<h5>{(makeItems.id > 0) ? t("setup.edit") : t("setup.new")} {t("setup.make")}</h5>}
            onAfterClose={res => {
                props.onHide();


                if (res) { saveMake(); }
            }}>
            <InnerModal btns={[{ 'color': 'warning', 'label': t("setup.cancel") }, { 'color': 'success', 'label': `${t("setup.save")} ${t("setup.category")}` }]}>
                <div className="row">
                    <div className="col-10">
                        <div className="form-group">
                            <label for="">{t("setup.make")} {t("setup.name")}: </label>
                            <input type="text" id="makeName" name="makeName" className="form-control form-control-sm" 
                                value={makeItems.makeName} onChange={handleChange} />
                        </div>
                    </div>
                </div>
            </InnerModal>
        </StaticDialog>
    );
}

export default MakeForm;