import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DConnect, GetJCookie, HandleError, Loader, PostOptions, TableBase, showConfirm } from "../../utils/MojaveLib";
import moment from "moment";
import axios from "axios";
import _ from "lodash";

function Review(props){

    const [reviews, setReviews] = useState([]);
    const [isLoader, setLoader] = useState(false);

    const {t, i18n} = useTranslation('translation');

    const dropdowns = GetJCookie('kyka-prepo');

    const columns = [
        {name: `${t("review.date")}`, selector: row => moment(row.createdon).format('DD MMM, YYYY'), sortable: true, width: '120px'},
        {name: `${t("welcome.product")}`, selector: row => row.productId, width: '250px'},
        {name: `${t("review.customer")}`, selector: row => row.userId, width: '150px'},
        {name: `${t("welcome.review")}`, selector: row => row.review, width: '250px'},
        {name: `${t("review.ratings")}`, selector: row => row.ratings, width: '120px'},
        {name: ``, selector: row => formatAction(row.id, row), width: '100px'},
    ];

    useEffect(() => {
        getReviews();
    },[]);

    const getReviews = () => {
        setLoader(true);

        DConnect().get(`review`).then(res => {
            if(res.data.length > 0){
                setReviews(res.data);
            }else{
                setReviews([]);
            }
            
            setLoader(false);
        }).catch(error =>HandleError(error));
    }

    const changeStatus = (item, status) => {
        let idx = _.findIndex(reviews, item),
            newReview = [...reviews];

            newReview[idx].status = status;
        setReviews(newReview);

        item.status = status;

        axios(PostOptions('PUT', item, `review/${item.id}`));
    }

    const deleteReview = (row) => {
        showConfirm({title:`${t('welcome.review')}`, message:`${t("portal.confirmImgDel")}...`, btnText:[`${t("setup.delete")}`,`${t("cancel")}`]}).then(res => {
            if(res){
                axios(PostOptions('DELETE', row, `review/${row.id}`)).then(res => {
                    
                }).catch(error =>HandleError(error));

                getReviews();
            }
        });
    }

    const formatPrepo = (cell, area) => {
        let proponame = dropdowns[area] !== undefined && dropdowns[area].filter(drop => drop.prevalue == cell);

        return proponame.length > 0 ? proponame[0].prename :  cell;
    }

    const formatAction = (cell, row) =><div style={{fontSize:'20px', lineHeight:'20px'}}>
        {(cell == 0) ? 
        <span className="badge badge-danger" role="button" onClick={() => changeStatus(row, 1)}>{formatPrepo(cell,'status')}</span> :
        <span className="badge badge-success" role="button" onClick={() => changeStatus(row, 0)}>{formatPrepo(cell,'status')}</span>} &nbsp; | &nbsp; 
        <i className='material-icons text-danger' role="button" title={t("setup.delete")} onClick={() => deleteReview(row)}>delete</i>
    </div>;

    if(isLoader){
        return <Loader />;
    }

    return(
        <div className="row p-4">
            <div className="col p-4">
                <div className="adminHead d-flex flex-row justify-content-between">
                    <span>{t('review.title')}</span>
                    <div className="d-flex flex-row">
                        <button className="btn btn-sm ml-2" onClick={getReviews}>{t('review.reload')}</button>
                    </div>
                </div>
                <div className="reviewBody" id='reviewCon'>
                    <TableBase keys="id" data={reviews} columns={columns} striped dense hover responsive />    
                </div>
            </div>
        </div>
    )
}

export default Review;