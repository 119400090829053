import React, { useEffect, useState } from "react";
import { BaseURL, DConnect, GetJCookie, HandleError, MakeId, PostOptions, TableBase, showAlert, showConfirm } from "../../utils/MojaveLib";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Dto from "../../utils/serializers";
import AdvertForm from "./AdvertForm";
import _ from "lodash";

function Adverts(props){

    const [adverts, setAdverts] = useState([]);
    const [advertItem, setAdvertItem] = useState(Dto.multimedia);
    const [categories, setCategories] = useState([]);
    const [title, setTitle] = useState(props.title);
    const [smShow, setSmShow] = useState(false);

    const {t, i18n} = useTranslation('translation');

    const dropdowns = GetJCookie('kyka-prepo');
    const columns = [
        {name: `${t("setup.ad")}`, selector: row => formatImage(row.filename, row), sortable: true},
        {name: `Category Linked`, selector: row => formatCat(row.caption, row), sortable: true},
        {name: '', selector: row => formatAction(row.id, row), sortable: true},
    ];

    useEffect(() => {
        setTitle(props.title);

        getInitData();

    }, [i18n, props.title]);

    const getInitData = () => {
        DConnect().get(`media?owner=advert`).then(res => {
            setAdverts(res.data);
        }).catch(error =>HandleError(error));

        DConnect().get(`category`).then(res => {

            if(res.data.length > 0){
                setCategories(res.data);
            }
        }).catch(error =>HandleError(error));
    }

    const showDialog = (advertItem) => {
        setAdvertItem(advertItem);

        setSmShow(true);
    }

    const smClose = () => setSmShow(false);

    const formatImage = (cell, row) => 
        <img src={`${BaseURL()}pic/advert/300x300/${cell}`} alt="..." style={{height:'40px'}} className="bg-danger" />;

    const formatCat = (cell, row) => {
        let catname = categories.filter(cat => cat.id == cell);

        return (catname.length > 0) ? catname[0].categoryName :  cell;
    }
    
    const formatAction = (cell, row) => <div style={{fontSize:'20px', lineHeight:'20px'}}> 
        <i className='material-icons text-primary' role="button" title={t("setup.edit")} onClick={() => showDialog(row)}>edit</i> &nbsp; | &nbsp;
        <i className='material-icons text-danger' role="button" title={t("setup.delete")} onClick={() => deleteImage(row)}>delete</i>
    </div>;

    const deleteImage = (image) => {
        showConfirm({title:`${t('portal.images')}`, message:`${t("portal.confirmImgDel")}...`, btnText:[`${t("setup.delete")}`,`${t("cancel")}`]}).then(res => {
            if(res){
                let extImages = [...adverts],
                    idx = _.findIndex(extImages, image);

                if(idx > -1){
                    extImages.splice(idx, 1);

                    setAdverts(extImages);

                    axios(PostOptions('DELETE', image, `media/${image.id}`));
                }
            }
        });

    }

    const saveAdvert = (image, advertItem) => {

        let filename = '', method = 'POST', url = 'media', catid = MakeId(5);
        
        if(image.ext !== ''){
            let fname = advertItem.filename.split('.'),
                formData = new FormData();

                // alert(fname[0].trim())

            if(advertItem.id > 0 && fname.length === 2 && fname[0].trim() !== ''){
                filename = `${fname[0]}.${image.ext}`;
            }else{
                filename = `advert_${catid}.${image.ext}`;
            }

            advertItem.filename = filename;

            formData.append('filename', `${filename}`);
            formData.append('foldername', `advert`);
            formData.append('imagefile', image.image);

            axios.post(`${BaseURL()}api/v1.0/fileupload`,formData).then(res => {

                showAlert({ title: t("welcome.advert"), message: `${t("welcome.advert")} ${t("setup.saved_success")}...`, btnText: t("setup.ok") })
                .then(imgres => getInitData());
            }).catch(error => console.log(error.message));
        } 
        
        if(advertItem.id > 0){
            method = "PUT";
            url = `media/${advertItem.id}`
        }else{
            advertItem.mediaType = 1;
            advertItem.owner='advert';
            advertItem.ownerId=catid;
        }

        console.log(JSON.stringify(advertItem))
        axios(PostOptions(method, {data: advertItem}, url)).then(res => {

            if(image.ext === ''){
                showAlert({ title: t("welcome.advert"), message: `${t("welcome.advert")} ${t("setup.saved_success")}...`, btnText: t("setup.ok") })
                .then(imgres => getInitData());
            }
        }).catch(error => console.log(error.message));
    }

    return(
        <div>
            <div className="adminHead d-flex flex-row justify-content-between">
                <span>{title}</span>
                <div className="d-flex flex-row">
                    <button className="btn btn-sm ml-2" onClick={() => showDialog({...Dto.multimedia})}>{t("setup.new")} {t("setup.ad")}</button>
                </div>
            </div>
            <div className="adminBody">
                <TableBase keys="id" data={_.orderBy(adverts,'sortOrder')} columns={columns} striped dense hover responsive />
            </div>

            <AdvertForm show={smShow} onHide={smClose} saveAdvert={saveAdvert} advertItem={advertItem} categories={categories} />
        </div>
    );
}

export default Adverts;
