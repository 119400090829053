import logo from './logo.svg';
import './App.css';
import 'bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.js';
import Login from './components/auth/Login';
import DefaultLayout from './components/layout/DefaultLayout';
import { IsAuthenticated } from './utils/MojaveLib';

const App = () => (IsAuthenticated()) ? <DefaultLayout /> : <Login />;

export default App;