import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { StaticDialog } from "react-st-modal";
import { BaseURL, GetJCookie, InnerModal, showAlert } from "../../utils/MojaveLib";
import $ from 'jquery';

function AdvertForm(props){

    const [image, setImage] = useState({ 'image': '', 'file': '', 'ext': ''});
    const [advertItem, setAdvertItem] = useState(props.advertItem);
    const [categories, setCategories] = useState(props.categories);

    const {t, i18n} = useTranslation('translation');

    const dropdowns = GetJCookie('kyka-prepo');

    useEffect(() => {
        setAdvertItem(props.advertItem);
        setCategories(props.categories)
        
    },[props.advertItem, props.categories]);

    const handleChange = (e) => {
        e.preventDefault();

        const {name, value} = e.target;

        setAdvertItem(prevProps => ({...prevProps, [name]:value}));
    }

    const imageChange = (e) => {
        e.preventDefault();

        let img = e.target.files[0],
            ext = '';

        // alert(files.length);
        let reader = new FileReader();

        ext = img.name.split('.');
        ext = ext[ext.length - 1].toLowerCase();

        if (!['jpg', 'jpeg', 'png'].includes(ext)) {
            showAlert({ title: "Unsupported format", message: "You can upload only JPG, JPEG, or PNG files", btnText: 'Ok' });
            return;
        }

        reader.onloadend = () => {
            let curImage = { 'image': '', 'file': '', 'ext': ''};

            curImage['image'] = img;
            curImage['file'] = reader.result;
            curImage['ext'] = ext;

            setImage(curImage);

            // saveAdvert(curImage);
        }

        reader.readAsDataURL(img);
    }

    const saveAdvert = () => props.saveAdvert(image, {...advertItem});

    return(
        <StaticDialog isOpen={props.show} isCanClose={false} title={<h5>{(advertItem.id > 0) ? t("setup.edit") : t("setup.new")} {''}</h5>}
            onAfterClose={res => {
                props.onHide();


                if (res) { saveAdvert(); }
            }}>
            <InnerModal btns={[{ 'color': 'warning', 'label': t("setup.cancel") }, { 'color': 'success', 'label': `${t("setup.save")} ${''}` }]}>
                <div className="row">
                    <div className="col-10">
                        <div className="idImg bg-g10 " role='button' style={{overflowX:'hidden', marginRight:'10px', height: '140px'}}>
                            <img src={(image.file == '') ? `${BaseURL()}pic/advert/300x300/${advertItem.filename}` :image.file} style={{height:'140px', cursor:"pointer" }} alt="Advert Image" onClick={() => $(`#advertImage`).click()} />
                            <input type="file" style={{ display: 'none' }} id="advertImage" onChange={imageChange} />
                        </div>
                        <div className="form-group">
                            <label for="">Order: </label>
                            <select type="text" id="sortOrder" name="sortOrder" className="form-control form-control-sm" 
                                value={advertItem.sortOrder} onChange={handleChange}>
                                    {[...Array(20).keys()].map(num => 
                                        <option value={num}>{num}</option>)}
                            </select>
                        </div>
                        <div className="form-group">
                            <label for="">Link to Category: </label>
                            <select type="text" id="caption" name="caption" className="form-control form-control-sm" 
                                value={advertItem.caption} onChange={handleChange}>
                                    <option value="">-- Select Category --</option>
                                    {categories.map(category => 
                                        <option value={category.id}>{category.categoryName}</option>)}
                            </select>
                        </div>
                    </div>
                </div>
            </InnerModal>
        </StaticDialog>
    );
}

export default AdvertForm;