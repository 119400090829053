import React from 'react';
import Dto from '../../utils/serializers';
import ReactDOM from 'react-dom';
import { useState } from 'react';
import { CurFormat, DConnect, DecryptData, GetJCookie, HandleError, TableBase } from '../../utils/MojaveLib';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';
import OrderDetails from './OrderDetails';

function OrderList(props){

    const [orders, setOrders] = useState([]);
    const [users, setUsers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [status, setStatus] = useState(props.status);

    const {t, i18n} = useTranslation('translation');

    const dropdowns = GetJCookie('kyka-prepo');

    const columns = [
        {name: 'Order Date', selector: row => moment(row.created_on).format('DD MMM, YYYY'), sortable: true},
        {name: 'Order No', selector: row => formatOrderNo(row.id), sortable: true, right:true},
        {name: 'Amount Paid', selector: row => formatTotal(row.totalAmount, row), sortable: true, right:true},
        {name: '', selector: row => formatAction(row.id, row), sortable: true},
    ];

    useEffect(() => {

        setStatus(props.status);

        if(props.status !== undefined){
            getOrderData(props.status);
        }
    },[props.status]);

    const getOrderData = (status) => {
        DConnect().get(`order/status/${status}`).then(res => {
            
            // alert(JSON.stringify(res.data))
            if(res.data.length > 0){
                setOrders(res.data);
            }else{
                setOrders([]);
            }
        }).catch(error =>alert(error.message));
    }

    const formatOrderNo = (num) => {
        let newNum = `${num}`;
        while(newNum.length < 8){
            newNum = `0${newNum}`;
        }

        return newNum;
    }
    const formatTotal = (cell, row) => CurFormat(cell + row.freightTotal - (row.discount + row.voucher));

    const formatAction = (cell, row) => <div className='text-primary' role="button" title='' onClick={() => showDetails(row)}>
        <i className='material-icons'>check</i> <small>Deatils</small>
    </div>;

    const showDetails = (row) => ReactDOM.render(<OrderDetails order={row} status={status} />, document.getElementById('orderCon'));

    return(
        <div className='row'>
            <div className='col'>
                <TableBase keys="id" data={_.orderBy(orders, 'created_on', 'desc')} columns={columns} striped dense hover responsive pagination />
            </div>
        </div>
    )
}

export default OrderList;