import React, {useState, useEffect} from "react";
import axios from 'axios';
import { CurFormat, DConnect, GetJCookie, HandleError, PostOptions, TableBase, showAlert } from "../../utils/MojaveLib";
import { useTranslation } from "react-i18next";
import DeliveryForm from "./DeliveryForm";
import Dto from "../../utils/serializers";
import _ from "lodash";

function Delivery(props){

    const [deliveryItems, setDeliveryItems] = useState([]);
    const [pickupPoints, setPickupPoints] = useState({});
    const [deliveryItem, setDeliveryItem] = useState(Dto.deliverySetup);
    const [cities, setCities] = useState([]);
    const [smShow, setSmShow] = useState(false);

    const {t, i18n} = useTranslation('translation');

    const dropdowns = GetJCookie('kyka-prepo');

    const columns = [
        {name: 'Charge type', selector: row => formatArea(row.chargeType,row), sortable: true},
        {name: 'Service', selector: row => formatDrop(row.service,'delivery_service'), sortable: true},
        {name: 'Origin', selector: row => formatCity(row.origin, row), sortable: true},
        {name: 'Destination', selector: row => formatCity(row.destination, row), sortable: true},
        {name: 'Multiplier', selector: row => CurFormat(row.multiplier), sortable: true},
        {name: '', selector: row => formatAction(row.id, row), sortable: true}
    ];

    useEffect(() => {
        getInitData();
    },[]);

    const getInitData = () => {
        
        DConnect().get(`city`).then(res => {
            if(res.data.length > 0){
                setCities(res.data);
            }else{
                setCities([]);
            }
        }).catch(error =>HandleError(error));

        DConnect().get(`delivery`).then(res => {
            if(res.data.length > 0){
                setDeliveryItems(res.data);
            }else{
                setDeliveryItems([]);
            }
        }).catch(error =>HandleError(error));

        DConnect().get(`pickup`).then(res => {
            if(res.data.length > 0){
                setPickupPoints(_.groupBy(res.data, 'city'));
            }else{
                setPickupPoints({});
            }
        }).catch(error =>HandleError(error));
    }

    const formatCity = (cell, row) => {
        let cityName = cities.filter(city => city.id == cell);

        return cityName.length > 0 ? cityName[0].cityName : cell;
    }

    const formatDrop = (cell, area) => {
        let dropname = dropdowns[area] !== undefined && dropdowns[area].filter(drop => drop.prevalue == cell);

        return (dropname.length > 0) ? dropname[0].prename : cell;
    }

    const formatArea = (cell, row) => `${formatDrop(cell,'delivery_charge_type')} (1 ${formatDrop(row.unitOfMeasure, 'unit_of_measure')}3)`

    const showDialog = (deliveryItem) => {
        setDeliveryItem(deliveryItem);

        setSmShow(true);
    }

    const smClose = () => setSmShow(false);

    const formatAction = (cell, row) => <div style={{fontSize:'20px', lineHeight:'20px'}}>
        <i className='material-icons text-success' role="button" title={t("setup.edit")} onClick={() => showDialog(row)}>edit</i> &nbsp; | &nbsp; 
        <i className='material-icons text-danger' role="button" title={t("setup.delete")}>delete</i>
    </div>;

    const saveDeliverSetup = (formData) => {

        let url =  `delivery`, protocol = 'POST';

        if(formData.id > 0){
            url = `delivery/${formData.id}`;
            protocol = 'PUT';
        }

        axios(PostOptions(protocol, formData, url)).then(res => {
            let resdata = res.data;

            getInitData();

            showAlert({ title: `Delivery Setup`, message: `Delivery Setup ${t("setup.saved_success")}...`, btnText: t("setup.ok") });
        }).catch(error => { 
            showAlert({ title: `Delivery Setup`, message: `Delivery Setup ${t("setup.city")}...${error.message}`, btnText: t("setup.ok") });
        });
    }

    return(
        <div>
            <div className="adminHead d-flex flex-row justify-content-between">
                <span>Delivery Setup</span>
                <div className="d-flex flex-row">
                    <button className="btn btn-sm ml-2" onClick={() => showDialog(Dto.deliverySetup)}>New Delivery Setup</button>
                </div>
            </div>
            <div className="adminBody">
                <TableBase keys="id" data={deliveryItems} columns={columns} striped dense hover responsive />
            </div>

            <DeliveryForm show={smShow} onHide={smClose} cities={cities} pickupPoints={pickupPoints}
                saveDeliverSetup={saveDeliverSetup} deliveryItem={deliveryItem} />
        </div>
    )
}

export default Delivery;