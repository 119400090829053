import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { StaticDialog } from "react-st-modal";
import { GetJCookie, InnerModal } from "../../utils/MojaveLib";

function SubCategoryForm(props){

    const [catSubItems, setCatSubItems] = useState(props.catSubItems);

    const {t, i18n} = useTranslation('translation');

    const dropdowns = GetJCookie('kyka-prepo');

    useEffect(() => {
        setCatSubItems(props.catSubItems);

    },[props.catSubItems]);

    const handleChange = (e) => {
        e.preventDefault();

        const {name, value} = e.target;

        setCatSubItems(prevProps => ({...prevProps, [name]:value}));
    }

    const saveSubCategory = () => props.saveSubCategory(catSubItems);

    return(
        <StaticDialog isOpen={props.show} isCanClose={false} title={<h5>{(catSubItems.id > 0) ? t("setup.edit") : t("setup.new")} {t("setup.subcats")}</h5>}
            onAfterClose={res => {
                props.onHide();


                if (res) { saveSubCategory(); }
            }}>
            <InnerModal btns={[{ 'color': 'warning', 'label': t("setup.cancel") }, { 'color': 'success', 'label': `${t("setup.save")} ${t("setup.subcats")}` }]}>
                <div className="row">
                    <div className="col-10">
                        <div className="form-group">
                            <label for="">{t("setup.name")}: </label>
                            <input type="text" id="name" name="name" className="form-control form-control-sm" 
                                value={catSubItems.name} onChange={handleChange} />
                        </div>
                        {/* <div className="form-group">
                            <label for="lang">{t("setup.icon")}: </label>
                            <select id="icon" name="icon" className="form-control form-control-sm" 
                                value={catSubItems.icon} onChange={handleChange} required>
                                    <option value=''>-- {t("setup.select")} {t("setup.icon")} --</option>
                                    {dropdowns.icon !== undefined && dropdowns.icon.map(drop => 
                                    <option value={drop.prevalue}>{drop.prename}</option>)}
                            </select>
                        </div> */}
                    </div>
                </div>
            </InnerModal>
        </StaticDialog>
    );
}

export default SubCategoryForm;