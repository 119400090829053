import React, { useState, useEffect } from "react";
import { StaticDialog } from "react-st-modal";
import { GetJCookie, InnerModal } from "../../utils/MojaveLib";
import { useTranslation } from "react-i18next";

function DeliveryForm(props){

    const [deliveryItem, setDeliveryItem] = useState(props.deliveryItem);
    const [cities, setCities] = useState(props.cities);
    const [pickupPoints, setPickupPoints] = useState(props.pickupPoints);

    const {t, i18n} = useTranslation('translation');

    const dropdowns = GetJCookie('kyka-prepo');

    useEffect(() => {

        setDeliveryItem(props.deliveryItem);
        setPickupPoints(props.pickupPoints);
        setCities(props.cities);

    },[props.deliveryItem, props.pickupPoints, props.cities]);

    const handleChange = (e) => {
        e.preventDefault();

        const {name, value} = e.target;

        setDeliveryItem(prevProps => ({...prevProps, [name]:value}));
    }

    const saveDeliverSetup = () => props.saveDeliverSetup({...deliveryItem});

    return (
        <StaticDialog isOpen={props.show} isCanClose={false} title={<h5>{(deliveryItem.id > 0) ? t("setup.edit") : t("setup.new")} Delivery Setup</h5>}
            onAfterClose={res => {
                props.onHide();


                if (res) { saveDeliverSetup(); }
            }}>
            <InnerModal btns={[{ 'color': 'warning', 'label': t("setup.cancel") }, { 'color': 'success', 'label': `${t("setup.save")} Delivery Setup` }]}>
                <div className="row">
                    <div className="col-10">
                        <div className="form-group">
                            <label for="origin">Origin: </label>
                            <select id="origin" name="origin" className="form-control form-control-sm" 
                                value={deliveryItem.origin} onChange={handleChange} >
                                    <option value="">-- Select city --</option>
                                    {cities.map(city => 
                                    <option value={city.id}>{city.cityName}</option>)}
                            </select>
                        </div>
                        <div className="form-group">
                            <label for="destination">Destination: </label>
                            <select id="destination" name="destination" className="form-control form-control-sm" 
                                value={deliveryItem.destination} onChange={handleChange} >
                                    <option value="">-- Select city --</option>
                                    {cities.map(city => 
                                    <option value={city.id}>{city.cityName}</option>)}
                            </select>
                        </div>
                        <div className="form-group">
                            <label for="">Transit Period: </label>
                            <div className="input-group input-group-sm">
                                <input type="number" id="transitPeriod" name="transitPeriod" className="form-control form-control-sm" 
                                    value={deliveryItem.transitPeriod} onChange={handleChange} min={0} />
                                <select id="periodType" name="periodType" className="form-control form-control-sm input-group-addon" 
                                    value={deliveryItem.periodType} onChange={handleChange}>
                                        {dropdowns.transit_period !== undefined && dropdowns.transit_period.map(period => 
                                        <option value={period.prevalue}>{period.prename}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label for="">Charge Type / Unit: </label>
                            <div className="input-group input-group-sm">
                                <select id="chargeType" name="chargeType" className="form-control form-control-sm" 
                                    value={deliveryItem.chargeType} onChange={handleChange}>
                                        {dropdowns.delivery_charge_type !== undefined && dropdowns.delivery_charge_type.map(charge => 
                                        <option value={charge.prevalue}>{charge.prename}</option>)}
                                </select>
                                <select id="unitOfMeasure" name="unitOfMeasure" className="form-control form-control-sm input-group-addon" 
                                    value={deliveryItem.unitOfMeasure} onChange={handleChange}>
                                        {dropdowns.unit_of_measure !== undefined && dropdowns.unit_of_measure.map(measure => 
                                        <option value={measure.prevalue}>{measure.prename}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label for="">Multiplier / Service: </label>
                            <div className="input-group input-group-sm">
                                <input type="number" id="multiplier" name="multiplier" className="form-control form-control-sm" 
                                value={deliveryItem.multiplier} onChange={handleChange} min={0} step="any" />
                                <select id="service" name="service" className="form-control form-control-sm input-group-addon" 
                                    value={deliveryItem.service} onChange={handleChange}>
                                        {dropdowns.delivery_service !== undefined && dropdowns.delivery_service.map(service => 
                                        <option value={service.prevalue}>{service.prename}</option>)}
                                </select>
                            </div>
                        </div>
                        {deliveryItem.service == 1 &&
                        <div className="form-group">
                            <label for="">Pickup Point: </label>
                            <select id="pickupPoint" name="pickupPoint" className="form-control form-control-sm" 
                                value={deliveryItem.pickupPoint} onChange={handleChange}>
                                    <option value=''>-- Select pickup point --</option>
                                    {pickupPoints[`${deliveryItem.destination}`] !== undefined && pickupPoints[`${deliveryItem.destination}`].map(pickup => 
                                    <option value={pickup.id}>{pickup.location}</option>)}
                            </select>
                        </div>}
                    </div>
                </div>
            </InnerModal>
        </StaticDialog>
    )
}

export default DeliveryForm;