import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { StaticDialog } from "react-st-modal";
import $ from "jquery";
import { GetJCookie, InnerModal, showAlert } from "../../utils/MojaveLib";

function CategoryForm(props){

    const [categoryItems, setCategoryItems] = useState(props.categoryItems);
    const [image, setImage] = useState(props.image);

    const {t, i18n} = useTranslation('translation');

    const dropdowns = GetJCookie('kyka-prepo');

    useEffect(() => {
        setCategoryItems(props.categoryItems);
        setImage(props.image);

    },[props.categoryItems, props.image]);

    const imageChange = (e) => {
        e.preventDefault();

        let img = e.target.files[0],
            ext = '';

        // alert(files.length);
        let reader = new FileReader();

        ext = img.name.split('.');
        ext = ext[ext.length - 1].toLowerCase();

        if (!['jpg', 'jpeg', 'png'].includes(ext)) {
            showAlert({ title: "Unsupported format", message: "You can upload only JPG, JPEG, or PNG files", btnText: 'Ok' });
            return;
        }

        reader.onloadend = () => {
            let curImage = { 'image': '', 'file': '', 'ext': ''};

            curImage['image'] = img;
            curImage['file'] = reader.result;
            curImage['ext'] = ext;

            setImage(curImage);
        }

        reader.readAsDataURL(img);
    }

    const handleChange = (e) => {
        e.preventDefault();

        const {name, value} = e.target;

        setCategoryItems(prevProps => ({...prevProps, [name]:value}));
    }

    const saveCategory = () => {
        props.saveCategory(categoryItems, image);
    }

    return(
        <StaticDialog isOpen={props.show} isCanClose={false} title={<h5>{(categoryItems.id > 0) ? t("setup.edit") : t("setup.new")} {t("setup.category")}</h5>}
            onAfterClose={res => {
                props.onHide();


                if (res) { saveCategory(); }
            }}>
            <InnerModal btns={[{ 'color': 'warning', 'label': t("setup.cancel") }, { 'color': 'success', 'label': `${t("setup.save")} ${t("setup.category")}` }]}>
                <div className="row">
                    <div className="col-10">
                        <div className="form-group">
                            <label for="">{t("setup.category")} {t("setup.name")}: </label>
                            <input type="text" id="categoryName" name="categoryName" className="form-control form-control-sm" 
                                value={categoryItems.categoryName} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label for="lang">{t("setup.icon")}: </label>
                            <div className="bg-warning" style={{width:'128px', height:'128px', overflow:'hidden', cursor:'pointer'}} onClick={() => $(`#categoryImage`).click()}>
                                {image.file !== '' &&
                                <img src={image.file} alt="..." style={{width:'126px'}} />}
                                <input type="file" style={{ display: 'none' }} id="categoryImage" onChange={imageChange} />
                            </div>

                            {/* <select id="icon" name="icon" className="form-control form-control-sm" 
                                value={categoryItems.icon} onChange={handleChange} required>
                                    <option value=''>-- {t("setup.select")} {t("setup.icon")} --</option>
                                    {dropdowns.icon !== undefined && dropdowns.icon.map(drop => 
                                    <option value={drop.prevalue}>{drop.prename}</option>)}
                            </select> */}
                        </div>
                    </div>
                </div>
            </InnerModal>
        </StaticDialog>
    );
}

export default CategoryForm;