import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetJCookie, InnerModal } from "../../utils/MojaveLib";
import { StaticDialog } from "react-st-modal";

function RegionForm(props){

    const [regionItems, setRegionItems] = useState(props.regionItems);

    const {t, i18n} = useTranslation('translation');

    useEffect(() => {
        setRegionItems(props.regionItems);

    },[i18n, props.regionItems]);

    const handleChange = (e) => {
        e.preventDefault();

        const {name, value} = e.target;

        setRegionItems(prevProps => ({...prevProps, [name]:value}));
    }

    const saveRegion = () => props.saveRegion(regionItems);

    return(
        <StaticDialog isOpen={props.show} isCanClose={false} title={<h5>{(regionItems.id > 0) ? t("setup.edit") : t("setup.new")} {t("setup.category")}</h5>}
            onAfterClose={res => {
                props.onHide();


                if (res) { saveRegion(); }
            }}>
            <InnerModal btns={[{ 'color': 'warning', 'label': t("setup.cancel") }, { 'color': 'success', 'label': `${t("setup.save")} ${t("setup.category")}` }]}>
                <div className="row">
                    <div className="col-10">
                        <div className="form-group">
                            <label for="">{t("setup.region")} {t("setup.code")}: </label>
                            <input type="text" id="regionCode" name="regionCode" className="form-control form-control-sm" 
                                value={regionItems.regionCode} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label for="">{t("setup.region")} {t("setup.name")}: </label>
                            <input type="text" id="regionName" name="regionName" className="form-control form-control-sm" 
                                value={regionItems.regionName} onChange={handleChange} />
                        </div>
                    </div>
                </div>
            </InnerModal>
        </StaticDialog>
    );
}

export default RegionForm;