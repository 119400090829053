import React, {useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';
import ReactDOM from "react-dom";
import { DConnect, GetJCookie, GetUser, HandleError, PostOptions, TableBase, showAlert } from "../../utils/MojaveLib";
import Dto from "../../utils/serializers";
import City from "./City";
import axios from "axios";
import MakeForm from "./MakeForm";
import MakeModel from "./MakeModel";

function Make(props){

    const [makeItems, setMakeItems] = useState(Dto.makes);
    const [title, setTitle] = useState(props.title);
    const [makes, setMakes] = useState([]);
    const [smShow, setSmShow] = useState(false);

    const {t, i18n} = useTranslation('translation');

    const dropdowns = GetJCookie('kyka-prepo');

    const columns = [
        {name: t("setup.code"), selector: row => row.id, sortable: true},
        {name: `${t("setup.make")} ${t("setup.name")}`, selector: row => row.makeName, sortable: true},
        {name: '', selector: row => formatAction(row.id, row), sortable: true},
    ];

    useEffect(() => {
        setTitle(props.title);

        getInitData();
    },[props.title]);

    const getInitData = () => {
        DConnect().get(`make`).then(res => {

            if(res.data.length > 0){
                setMakes(res.data);
            }
        }).catch(error =>HandleError(error));
    }

    const showDialog = (makeItems) => {
        setMakeItems(makeItems);

        setSmShow(true);
    }

    const smClose = () => setSmShow(false);

    const saveMake = (formData) => {
        let url =  `make`, protocol = 'POST';

        if(formData.id > 0){
            url = `make/${formData.id}`;
            protocol = 'PUT';
        }

        axios(PostOptions(protocol,formData, url)).then(res => {
            let resdata = res.data;

            getInitData();

            showAlert({ title: t("setup.make"), message: `${t("setup.make")} ${t("setup.saved_success")}...`, btnText: t("setup.ok") });
        }).catch(error => { 
            showAlert({ title: t("setup.make"), message: `${t("setup.saved_error")} ${t("setup.make")}...${error.message}`, btnText: t("setup.ok") });
        });
    }

    const formatAction = (cell, row) => <div style={{fontSize:'20px', lineHeight:'20px'}}>
        <i className='material-icons text-success' role="button" title={t("setup.edit")} onClick={() => showDialog(row)}>edit</i> &nbsp; | &nbsp; 
        {/* <i className='material-icons text-success' role="button" title={t("setup.model")} onClick={() => showModels(row)}>account_balance</i> &nbsp; | &nbsp;  */}
        <i className='material-icons text-danger' role="button" title={t("setup.delete")}>delete</i>
    </div>;

    const showModels = (make) => 
            ReactDOM.render(<MakeModel subTitle={make.makeName} makeId={make.id} title={title} />, document.getElementById("adminCon"));

    return (
        <div>
            <div className="adminHead d-flex flex-row justify-content-between">
                <span>{title}</span>
                <div className="d-flex flex-row">
                    <button className="btn btn-sm ml-2" onClick={() => showDialog({...Dto.makes})}>{t("setup.new")} {t("setup.make")}</button>
                </div>
            </div>
            <div className="adminBody">
                <TableBase keys="id" data={makes} columns={columns} striped dense hover responsive />
            </div>

            <MakeForm show={smShow} onHide={smClose} saveMake={saveMake} makeItems={makeItems} />
        </div>
    );
}

export default Make;