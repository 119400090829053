import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import GeneralSetup from "./GeneralSetup";
import Dropdown from "./Dropdown";
import Category from "./Category";
import Region from "./Region";
import UserRole from "./UserRole";
import Portal from "./Portal";
import Make from "./Make";
import Delivery from "../delivery/Delivery";
import PickupPoint from "../delivery/PickupPoint";
import Adverts from "./Adverts";

function PageAdmin(props){

    
    const {t, i18n} = useTranslation('translation');
    const adminMenu = [
        t("setup.general"), t("setup.dropdown"), t("setup.category"), t("setup.make"), 
        t("setup.region"), t("setup.userole"), t("setup.portal"), t("setup.delivery"), 
        t("setup.pickup"), t("setup.ad")];

    useEffect(() => {
        
        showPages(0);

    },[i18n]);

    const showPages = (page) => {
        $(`[id^="adminBtn_"]`).removeClass('active');
        $(`#adminBtn_${page}`).addClass('active');

        const adminPages = [
            <GeneralSetup title={adminMenu[page]} />, 
            <Dropdown title={adminMenu[page]} />, 
            <Category title={adminMenu[page]} />, 
            <Make title={adminMenu[page]} />, 
            <Region title={adminMenu[page]} />, 
            <UserRole title={adminMenu[page]} />, 
            <Portal title={adminMenu[page]} />, 
            <Delivery title={adminMenu[page]} />, 
            <PickupPoint title={adminMenu[page]} />, 
            <Adverts title={adminMenu[page]} />
        ];

        ReactDOM.render(adminPages[page], document.getElementById('adminCon'));
    }

    return (
        <div className="row p-4">
            <div className="col-xs-12 col-md-9 col-lg-10 p-4" id="adminCon"></div>
            <div className="col-xs-12 col-md-3 col-lg-2 p-4  mt-4 adminBtnCon">
                <div className="btn-group btn-group-sm btn-group-vertical">
                    {adminMenu.map((amenu, idx) => 
                    <button id={`adminBtn_${idx}`} className={`btn ${(idx === 0) && 'active'}`} onClick={() => showPages(idx)}>{amenu}</button>)}
                </div>
            </div>
        </div>
    );
}

export default PageAdmin;