import React, { useState, useEffect } from "react";
import Dto from "../../utils/serializers";
import moment from "moment";
import ReactDOM from 'react-dom';
import axios from 'axios';
import { CurFormat, DConnect, DecryptData, GetJCookie, HandleError, PostOptions, TableBase, showAlert } from "../../utils/MojaveLib";
import { useTranslation } from "react-i18next";
import OrderList from "./OrderList";

function OrderDetails(props){

    const [order, setOrder] = useState(props.order);
    const [status, setStatus] = useState(props.status);
    const [customer, setCustomer] = useState(Dto.users);
    const [products, setProducts] = useState([]);

    const {t, i18n} = useTranslation('translation');

    const dropdowns = GetJCookie('kyka-prepo');
    const titles = {
        '1' :{'btn': 'Confirm Payment'}, '2' :{'btn': 'Start Processing'}, '3' :{'btn': 'Start Processing'},
        '4' :{'btn': 'Confirm Dispatched'}, '5' :{'btn': 'Confirm Delivery'}, '-1' :{'btn': ''}
    }

    useEffect(() => {
        setOrder(props.order);
        setStatus(props.status);

        getInitData(props.order);
    },[props.order, props.status]);

    const getInitData = (order) => {

        DConnect().get(`user/${order.profileId}`).then(res => {
            setCustomer(DecryptData(res.data));
        }).catch(error =>HandleError(error));

        DConnect().get(`order/batchid/${order.batchId}`).then(res => {
            setProducts(res.data);
        }).catch(error =>HandleError(error));
    }

    const formatOrderNo = (num) => {
        let newNum = `${num}`;
        while(newNum.length < 8){
            newNum = `0${newNum}`;
        }

        return newNum;
    }
    const formatPrepo = (cell, area) => {
        let proponame = dropdowns[area] !== undefined && dropdowns[area].filter(drop => drop.prevalue == cell);

        return proponame.length > 0 ? proponame[0].prename :  cell;
    }

    const processOrder = () => {
        let newStatus = {'1':2, '2':3, '3':4, '4':5, '5':0};
        axios(PostOptions('PATCH','', `order/${order.id}/status/${newStatus[`${order.status}`]}`)).then(res => {

            showAlert({ title: 'Order', message: `${titles[`${order.status}`].btn} successful...`, btnText: t("setup.ok") })
            .then(arg => goBack());
        });
    }

    const cancelOrder = () => {
        axios(PostOptions('PATCH','', `order/${order.id}/status/-1`)).then(res => {

            showAlert({ title: 'Order', message: `Order cancelled successfully...`, btnText: t("setup.ok") })
            .then(arg => goBack());
        });
    }

    const restorOrder = () => {
        axios(PostOptions('PATCH','', `order/${order.id}/status/1`)).then(res => {

            showAlert({ title: 'Order', message: `Order cancelled successfully...`, btnText: t("setup.ok") })
            .then(arg => goBack());
        });
    }

    const goBack = () => ReactDOM.render(<OrderList status={status} />, document.getElementById('orderCon'));

    return(
        <div className='row'>
            <div className='col'>
                <div className="d-flex flex-row justify-content-between p-4">
                    <div className="">
                        <div className="font-weight-bold">Customer Details</div>
                        <div>
                            {customer.firstName} {customer.lastName}<br />
                            {customer.email}<br />
                            {customer.mobile} / {customer.phone}
                        </div>
                        <div className="d-flex flex-row mt-3">
                            <div style={{width: '100px'}}>Pay Method:</div><div className="font-weight-bold">{formatPrepo(order.payMethod,'pay_method')}</div>
                        </div>
                        <div className="d-flex flex-row">
                            <div style={{width: '100px'}}>Name:</div><div className="font-weight-bold">{order.tranName}</div>
                        </div>
                        {order.payMethod == 2 &&
                        <div className="d-flex flex-row">
                            <div style={{width: '100px'}}>Trans ID:</div><div className="font-weight-bold">{order.tranId}</div>
                        </div>}
                    </div>
                    <div className="">
                        <div className="d-flex flex-row">
                            <div style={{width: '100px'}}>Order #:</div><div className="font-weight-bold">{formatOrderNo(order.id)}</div>
                        </div>
                        <div className="d-flex flex-row">
                            <div style={{width: '100px'}}>Order Date:</div><div className="font-weight-bold">{moment(order.created_on).format('DD MMM, YYYY')}</div>
                        </div>
                        <div className="d-flex flex-row">
                            <div style={{width: '100px'}}>Freight Type:</div><div className="font-weight-bold">{formatPrepo(order.freightType, 'delivery_service')}</div>
                        </div>
                        <div className="d-flex flex-row">
                            <div style={{width: '100px'}}>Order Status:</div><div className="font-weight-bold">{formatPrepo(order.status, 'order_status')}</div>
                        </div>
                        <div className="d-flex flex-row mt-3">
                            {order.status > 0 &&
                            <button className="btn btn-sm btn-success mr-2" onClick={processOrder}>{titles[`${order.status}`].btn}</button>}
                            {order.status > 0 &&
                            <button className="btn btn-sm btn-danger mr-2" onClick={cancelOrder}>Cancel Order</button>}
                            {order.status < 0 &&
                            <button className="btn btn-sm btn-danger mr-2" onClick={restorOrder}>Restore Order</button>}
                            <button className="btn btn-sm btn-warning" onClick={goBack}>Back</button>
                        </div>
                    </div>
                </div>
                <div className="">
                    {products.length > 0 && <table className="table table-condensed table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product</th>
                                <th>Unit Price</th>
                                <th>Quantity</th>
                                <th>Line Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products.map((product, idx) => 
                            <tr>
                                <td>{idx}</td>
                                <td>{product.title}</td>
                                <td style={{textAlign:'right'}}>{CurFormat(product.unit_price)}</td>
                                <td style={{textAlign:'right'}}>{CurFormat(product.quantity)}</td>
                                <td style={{textAlign:'right'}}>{CurFormat(product.unit_price * product.quantity)}</td>
                            </tr>)}
                            <tr>
                                <th></th>
                                <th></th>
                                <th colSpan={2}>Total</th>
                                <th style={{textAlign:'right'}}>{CurFormat(order.totalAmount)}</th>
                            </tr>
                            <tr>
                                <th></th>
                                <th></th>
                                <th colSpan={2}>Freight</th>
                                <th style={{textAlign:'right'}}>{CurFormat(order.freightTotal)}</th>
                            </tr>
                            <tr>
                                <th></th>
                                <th></th>
                                <th colSpan={2}>Discount</th>
                                <th style={{textAlign:'right'}}>{CurFormat(order.discount)}</th>
                            </tr>
                            <tr>
                                <th></th>
                                <th></th>
                                <th colSpan={2}>Coupon</th>
                                <th style={{textAlign:'right'}}>{CurFormat(order.voucher)}</th>
                            </tr>
                            <tr>
                                <th></th>
                                <th></th>
                                <th colSpan={2}>Grand Total</th>
                                <th style={{textAlign:'right'}}>{CurFormat(order.totalAmount + order.freightTotal - (order.discount + order.voucher))}</th>
                            </tr>
                        </tbody>
                    </table>}
                </div>
            </div>
        </div>
    )
}

export default OrderDetails;