import React, { useEffect, useState } from "react";
import { CurFormat, DConnect, GetJCookie, GetUser, HandleError, PostOptions, TableBase, showAlert } from "../../utils/MojaveLib";
import { useTranslation } from "react-i18next";
import PromotionForm from "./PromotionForm";
import Dto from "../../utils/serializers";
import axios from "axios";
import moment from "moment";
import _ from "lodash";

function PromotionList(props){

    const [promotions, setPromotions] = useState([]);
    const [promoType, setPromoType] = useState(props.promoType);
    const [promoItems, setPromoItems] = useState(Dto.promotion);

    const [smShow, setSmShow] = useState(false);

    const {t, i18n} = useTranslation('translation');

    const dropdowns = GetJCookie('kyka-prepo');
    const columns = [
        {name: `${t("coupon.coupon")} ${t("setup.name")}`, selector: row => row.promoName, sortable: true, width: '300px'},
        {name: `${t("coupon.validity")}`, selector: row => formatValidity(row.startDate, row), width: '250px'},
        {name: `${t("coupon.amount")}`, selector: row => CurFormat(row.amount), width: '150px'},
        {name: `${t("coupon.limit")}`, selector: row => formatLimit(row.lowLimit, row), width: '250px'},
        {name: `${t("coupon.usageType")}`, selector: row => formatPrepo(row.usageType, 'usage_type'), width: '120px'},
        {name: `${t("setup.status")}`, selector: row => formatAction(row.status, row), width: '150px'},
    ];

    useEffect(() => {
        setPromoType(props.promoType);

        getInitData(props.promoType);

    }, [i18n, props.promoType]);

    const getInitData = (promoType) => {

        if(promoType === undefined){
            return;
        }

        DConnect().get(`promotion/?promoType=${promoType.id}`).then(res => {
            setPromotions(res.data);
        }).catch(error =>HandleError(error));
    }

    const changeStatus = (item, status) => {
        let idx = _.findIndex(promotions, item),
            newPromo = [...promotions];

        newPromo[idx].status = status;
        setPromotions(newPromo);
        item.status = status;

        axios(PostOptions('PUT',item, `promotion/${item.id}`));
    }

    const showDialog = (promoItems) => {
        setPromoItems(promoItems);

        setSmShow(true);
    }

    const smClose = () => setSmShow(false);

    const formatValidity = (cell, row) => `${moment(cell).format('DD MMM, YYYY')} - ${moment(row.endDate).format('DD MMM, YYYY')}`;
    const formatLimit = (cell, row) => (cell == row.highLimit && cell == 0) ? '-' : `${CurFormat(cell)} - ${CurFormat(row.highLimit)}`;
    const formatPrepo = (cell, area) => {
        let proponame = dropdowns[area] !== undefined && dropdowns[area].filter(drop => drop.prevalue == cell);

        return proponame.length > 0 ? proponame[0].prename :  cell;
    }
    const formatAction = (cell, row) => <div>{(cell == 0) ? 
        <span className="badge badge-danger" role="button" onClick={() => changeStatus(row, 1)}>{formatPrepo(cell,'status')}</span> :
        <span className="badge badge-success" role="button" onClick={() => changeStatus(row, 0)}>{formatPrepo(cell,'status')}</span>} &nbsp; | &nbsp; 
        <span className="badge badge-warning" role="button" onClick={() =>  showDialog(row)}>Edit</span>
    </div>;

    const savePromotion = (formData) => {
        
        let url =  `promotion`, protocol = 'POST';

        if(formData.id > 0){
            url = `promotion/${formData.id}`;
            protocol = 'PUT';
            formData.modifiedBy = GetUser().id;
        }

        // alert(JSON.stringify(formData))
        axios(PostOptions(protocol, formData, url)).then(res => {
            let resdata = res.data;

            showAlert({ title: t("welcome.promotion"), message: `${t("coupon.coupon")} ${t("setup.saved_success")}...`, btnText: t("setup.ok") });
        }).catch(err => console.log(err.message));
    }

    return(
        <div>
            <div className="adminHead d-flex flex-row justify-content-between">
                <span>{t(`coupon.btn${promoType.id}`)} {t("welcome.promotion")}</span>
                <div className="d-flex flex-row">
                    <button className="btn btn-sm ml-2" onClick={() => showDialog({...Dto.promotion})}>{t("setup.new")} {t("welcome.promotion")}</button>
                </div>
            </div>
            <div className="adminBody">
                <TableBase keys="id" data={promotions} columns={columns} striped dense hover responsive />
            </div>

            <PromotionForm show={smShow} setSmShow={setSmShow} onHide={smClose} savePromotion={savePromotion} promoItems={promoItems} promoType={promoType}  />
        </div>
    );
}

export default PromotionList;
