import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StaticDialog } from "react-st-modal";
import { GetJCookie, InnerModal } from "../../utils/MojaveLib";

function UserRoleForm(props){

    const [roleItems, setRoleItems] = useState(props.roleItems);

    const {t, i18n} = useTranslation('translation');

    const dropdowns = GetJCookie('kyka-prepo');

    useEffect(() => {
        setRoleItems(props.roleItems);

    },[i18n, props.roleItems]);

    const handleChange = (e) => {
        e.preventDefault();

        const {name, value} = e.target;

        setRoleItems(prevProps => ({...prevProps, [name]:value}));
    }

    const saveRole = () => props.saveRole(roleItems);

    return(
        <StaticDialog isOpen={props.show} isCanClose={false} title={<h5>{(roleItems.id > 0) ? t("setup.edit") : t("setup.new")} {t("setup.role")}</h5>}
            onAfterClose={res => {
                props.onHide();


                if (res) { saveRole(); }
            }}>
            <InnerModal btns={[{ 'color': 'warning', 'label': t("setup.cancel") }, { 'color': 'success', 'label': `${t("setup.save")} ${t("setup.role")}` }]}>
                <div className="row">
                    <div className="col-10">
                        <div className="form-group">
                            <label for="">{t("setup.role_level")}: </label>
                            <select id="userLevel" name="userLevel" className="form-control form-control-sm" 
                                value={roleItems.userLevel} onChange={handleChange} required>
                                    <option value=''>-- {t("setup.select")} {t("setup.role_level")} --</option>
                                    {dropdowns.role_level !== undefined && dropdowns.role_level.map(drop =>
                                    <option value={drop.prevalue}>{drop.prename}</option>)}
                            </select>
                        </div>
                        <div className="form-group">
                            <label for="">{t("setup.role")} {t("setup.name")}: </label>
                            <input type="text" id="roleName" name="roleName" className="form-control form-control-sm" 
                                value={roleItems.roleName} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label for="">{t("setup.features")}: </label>
                            <input type="text" id="features" name="features" className="form-control form-control-sm" 
                                value={roleItems.features} onChange={handleChange} />
                        </div>
                    </div>
                </div>
            </InnerModal>
        </StaticDialog>
    );
}

export default UserRoleForm;