import React, { useEffect, useState } from "react";
import PageList from "./PageList";

function Page(props){

    const [page, setPage] = useState(props.page);

    useEffect(() => {
        setPage(props.page);

    },[props.page]);

    return (
        <div id="pageCon"><PageList page={page} /></div>
    );
}

export default Page;