import React, { useEffect } from 'react';
import Dto from '../../utils/serializers';
import ReactDOM from 'react-dom';
import { useState } from 'react';
import { GetJCookie, TableBase } from '../../utils/MojaveLib';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import PromotionList from './PromotionList';

function Promotions(props){

    const [promotions, setPromotions] = useState([]);
    const [promoType, setPromoType] = useState(0);

    const {t, i18n} = useTranslation('translation');

    const dropdowns = GetJCookie('kyka-prepo');

    const columns = [
        {name: t("setup.title"), selector: row => row.title, sortable: true},
        {name: t("setup.lastmodify"), selector: row => row.modifiedBy, sortable: true},
        {name: t("setup.status"), selector: row => row.status, sortable: true},
        {name: '', selector: row => formatAction(row.id, row), sortable: true},
    ];

    useEffect(() => {

    },[]);

    const showPages = (page) => {
        $(`[id^="promoBtn_"]`).removeClass('active');
        $(`#promoBtn_${page.prevalue}`).addClass('active');

        ReactDOM.render(<PromotionList promoType={{'id': page.prevalue, 'name': page.prename}} />, 
        document.getElementById('promoCon'));
    }

    const formatAction = (cell, row) => <div style={{fontSize:'20px', lineHeight:'20px'}}>
        <i className='material-icons text-success' role="button" title={t("setup.edit")} onClick={() => showEditForm(row)}>edit</i> 
    </div>;

    const showEditForm = () => {

    }

    return(
        <div className="orderBody">
            <div className="row p-4">
                <div className="col-xs-12 col-md-9 col-lg-10 p-4" id="promoCon"></div>
                <div className="col-xs-12 col-md-3 col-lg-2 p-4  mt-4 adminBtnCon">
                    <div className="btn-group btn-group-sm btn-group-vertical">
                        {dropdowns.promo_type !== undefined && dropdowns.promo_type.map((dropdown, idx) => 
                        <button id={`promoBtn_${dropdown.prevalue}`} className={`btn`} 
                            onClick={() => showPages(dropdown)}>{t(`coupon.btn${idx+1}`)}</button>)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Promotions;