import React, {useState, useEffect} from "react";
import axios from 'axios';
import { DConnect, HandleError, PostOptions, TableBase, showAlert } from "../../utils/MojaveLib";
import { useTranslation } from "react-i18next";
import PickupPointForm from "./PickupPointForm";
import Dto from "../../utils/serializers";

function PickupPoint(props){

    const [pickupPointItems, setPickupPointItems] = useState([]);
    const [pickupPointItem, setPickupPointItem] = useState(Dto.pickupPoint);
    const [cities, setCities] = useState([]);
    const [smShow, setSmShow] = useState(false);

    const {t, i18n} = useTranslation('translation');

    const columns = [
        {name: 'City', selector: row => formatCity(row.city, row), sortable: true},
        {name: 'Location', selector: row => row.location, sortable: true},
        {name: 'Mobile', selector: row => row.mobile, sortable: true},
        {name: 'Email', selector: row => row.email, sortable: true},
        {name: '', selector: row => formatAction(row.id, row), sortable: true}
    ];

    const formatAction = (cell, row) => <div style={{fontSize:'20px', lineHeight:'20px'}}>
        <i className='material-icons text-success' role="button" title={t("setup.edit")} onClick={() => showDialog(row)}>edit</i> &nbsp; | &nbsp; 
        <i className='material-icons text-danger' role="button" title={t("setup.delete")}>delete</i>
    </div>;

    useEffect(() => {
        getInitData();
    },[]);

    const getInitData = () => {

        DConnect().get(`city`).then(res => {
            if(res.data.length > 0){
                setCities(res.data);
            }else{
                setCities([]);
            }
        }).catch(error =>HandleError(error));

        DConnect().get(`pickup`).then(res => {
            if(res.data.length > 0){
                setPickupPointItems(res.data);
            }else{
                setPickupPointItems([]);
            }
        }).catch(error =>HandleError(error));
    }

    const showDialog = (pickupPointItem) => {
        setPickupPointItem(pickupPointItem);

        setSmShow(true);
    }

    const smClose = () => setSmShow(false);

    const formatCity = (cell, row) => {
        let cityName = cities.filter(city => city.id == cell);

        return cityName.length > 0 ? cityName[0].cityName : cell;
    }

    const savePickupPoint = (formData) => {
        let url =  `pickup`, protocol = 'POST';

        if(formData.id > 0){
            url = `pickup/${formData.id}`;
            protocol = 'PUT';
        }

        axios(PostOptions(protocol, formData, url)).then(res => {
            let resdata = res.data;

            getInitData();

            showAlert({ title: `Pickup Point`, message: `Pickup Point ${t("setup.saved_success")}...`, btnText: t("setup.ok") });
        }).catch(error => { 
            showAlert({ title: `Pickup Point`, message: `Pickup Point ${t("setup.city")}...${error.message}`, btnText: t("setup.ok") });
        });
    }

    return(
        <div>
            <div className="adminHead d-flex flex-row justify-content-between">
                <span>Pickup Points</span>
                <div className="d-flex flex-row">
                    <button className="btn btn-sm ml-2" onClick={() => showDialog(Dto.pickupPoint)}>New Pickup Point</button>
                </div>
            </div>
            <div className="adminBody">
                <TableBase keys="id" data={pickupPointItems} columns={columns} striped dense hover responsive />
            </div>

            <PickupPointForm show={smShow} onHide={smClose} cities={cities} savePickupPoint={savePickupPoint} pickupPointItem={pickupPointItem} />
        </div>
    )
}

export default PickupPoint;