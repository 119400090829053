import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GetJCookie } from "../../../utils/MojaveLib";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import $ from 'jquery';

function ProductExtra(props){

    const [productExtraItems, setProductExtraItems] = useState(props.productExtraItems);
    const [productItems, setProductItems] = useState(props.productItems);
    const [portalItems, setPortalItems] = useState(props.portalItems);

    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const {t, i18n} = useTranslation('translation');

    const dropdowns = GetJCookie('kyka-prepo');

    useEffect(() => {
        
        setProductExtraItems(props.productExtraItems);
        setProductItems(props.productItems);
        setPortalItems(props.portalItems);

        initData();

    },[i18n, props.productItems, props.portalItems]);

    const initData = () => {
        
    }

    const handleChange = (e) =>{
        // e.preventDefault();

        const {name, value} = e.target;

        if(['taxable'].includes(name)){
            setPortalItems(prevProps => ({...prevProps, [name]:e.target.checked}));
        }else{
            setPortalItems(prevProps => ({...prevProps, [name]:value}));
        }
    }

    const handleProductChange = (e) =>{
        // e.preventDefault();

        const {name, value} = e.target;

        if(['trackQty'].includes(name)){
            setProductItems(prevProps => ({...prevProps, [name]:e.target.checked}));
        }else{
            setProductItems(prevProps => ({...prevProps, [name]:value}));
        }
    }

    const submitForm = (e) => {
        e.preventDefault();

        props.handleChange(0,portalItems);

        let productData = {...productItems};
            productData.colors = selectedOptions.map(sel => sel.value).toString();
        props.handleChange(1,productData);

        props.doNavigate(props,3);
    }

    return(
        <div>
            <h5 className="text-primary mt-2 mb-0">{t('product.pdetail')}</h5>
            <hr className="mt-0" />
            <form id="frmPortalData" onSubmit={submitForm}>
            <div className="row">
                <div className="col-xs-12 col-md-6 col-lg-6">
                    <div className="form-group">
                        <label for="">{t("portal.shortitle")}: </label>
                        <input type="text" id="title" name="title" className="form-control form-control-sm" 
                            value={portalItems.title} onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <label for="">{t("portal.description")} </label>
                        <textarea id="description" name="description" className="form-control form-control-sm" 
                            value={productItems.description} onChange={handleProductChange} rows={7} required /> 
                    </div>

                    <div className="form-group">
                        <label for="">{t("portal.specs")} </label>
                        <textarea id="specs" name="specs" className="form-control form-control-sm" 
                            value={productItems.specs} onChange={handleProductChange} rows={7} /> 
                    </div>
                </div>
                <div className="col-xs-12 col-md-6 col-lg-6">
                    <div className="form-group">
                        <label for="">{t("portal.fulltitle")}: </label>
                        <input type="text" id="content" name="content" className="form-control form-control-sm" 
                            value={portalItems.content} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label for="">{t('portal.features')} </label>
                        <textarea id="features" name="features" className="form-control form-control-sm" 
                            value={productItems.features} onChange={handleProductChange} rows={7} /> 
                    </div>
                    
                </div>
            </div>
            </form>
            <hr />
            <div className="d-flex flex-row justify-content-between">
                <button className="btn btn-bg btn-sm btn-outline-primary" onClick={() => props.doNavigate(props,1)}>{t("setup.previous")} </button>
                <button type="submit" form="frmPortalData" className="btn btn-bg btn-sm btn-outline-primary" style={{width:'150px'}}> {t("setup.next")} </button>
            </div>
        </div>
    )
}

export default ProductExtra;