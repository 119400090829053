import React, { useEffect, useState } from "react";
import { StaticDialog } from "react-st-modal";
import { InnerModal, showAlert } from "../../utils/MojaveLib";
import { useTranslation } from "react-i18next";

function UserForm(props){

    const [userItems, setUserItems] = useState(props.userItems);

    const {t, i18n} = useTranslation('translation');

    useEffect(() => {
        setUserItems(props.userItems);

    },[i18n, props.userItems]);

    const handleChange = (e) => {
        e.preventDefault();

        const {name, value} = e.target;

        setUserItems(prevProps => ({...prevProps, [name]:value}));
    }

    const saveUser = () => {
        if(userItems.password === '' || (userItems.password !== userItems.repassword)){
            showAlert({ title: t("setup.user"), message: `${t("setup.pass_mismatch")}...`, btnText: t("setup.ok") });
            return;
        }

        props.onHide();

        props.saveUser(userItems);
    }

    return(
        <StaticDialog isOpen={props.show} isCanClose={false} title={<h5>{(userItems.id > 0) ? t("setup.edit") : t("setup.new")} {t("setup.user")}</h5>}
            onAfterClose={res => {
                if (res) { saveUser(); }
                else{props.onHide();}
            }}>
            <InnerModal btns={[{ 'color': 'warning', 'label': t("setup.cancel") }, { 'color': 'success', 'label': `${t("setup.save")} ${t("setup.user")}` }]}>
                <div className="row">
                    <div className="col-10">
                        <div className="form-group">
                            <label for="">{t("setup.fname")}: </label>
                            <input type="text" id="firstName" name="firstName" className="form-control form-control-sm" 
                                value={userItems.firstName} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label for="">{t("setup.lname")}: </label>
                            <input type="text" id="lastName" name="lastName" className="form-control form-control-sm" 
                                value={userItems.lastName} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label for="">{t("auth.email")}: </label>
                            <input type="email" id="email" name="email" className="form-control form-control-sm" 
                                value={userItems.email} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label for="">{t("auth.mobile")}: </label>
                            <input type="tel" id="mobile" name="mobile" className="form-control form-control-sm" 
                                value={userItems.mobile} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label for="">{t("auth.phone")}: </label>
                            <input type="tel" id="phone" name="phone" className="form-control form-control-sm" 
                                value={userItems.phone} onChange={handleChange} />
                        </div>

                        {userItems.id === 0 &&
                        <div className="form-group">
                            <label for="">{t("auth.password")}: </label>
                            <input type="password" id="password" name="password" className="form-control form-control-sm" 
                                value={userItems.password} onChange={handleChange} />
                        </div>}
                        {userItems.id === 0 &&
                        <div className="form-group">
                            <label for="">{t("auth.retype")} {t("auth.password")}: </label>
                            <input type="password" id="repassword" name="repassword" className="form-control form-control-sm" 
                                value={userItems.repassword} onChange={handleChange} />
                        </div>}
                    </div>
                </div>
            </InnerModal>
        </StaticDialog>
    );
}

export default UserForm;