import React, { useState, useEffect, useRef, Suspense } from "react";
import mojaveLogo from '../../utils/images/mojave_logo.png'
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import { GetCookie, GetJCookie, GetUser, Logout, SetCookie } from "../../utils/MojaveLib";
import PageWelcome from "../pages/PageWelcome";
import Page from "../pages/Page";
import PageAdmin from "../pages/PageAdmin";
import MyProfile from "../pages/MyProfille";
import Orders from "../orders/Orders";
import Promotions from "../pages/Promotions";
import Review from "../review/Review";

function DefaultLayout(){

    const dataFetch = useRef(false);
    const [defaultLang, setDefaultLang] = useState(GetCookie('kyka-language'));
    const {t, i18n} = useTranslation('translation');

    const CURRENT_URL = window.location.href.split('#')[0].split('?')[0];

    const user = GetUser();
    const dropdowns = GetJCookie('kyka-prepo');

    useEffect(() => {

        if (!dataFetch.current){
            dataFetch.current = true;

            __init();
        }
    },[]);

    const __init = () => {
        showPages(100);
        
    }

    const history = createBrowserHistory({
        basename: '/cms/'
    });

    const showPages = (page) => {
        $(`[id^="menuBtn_"]`).removeClass('active');
        $(`#menuBtn_${page}`).addClass('active');

        if(page === 100){
            ReactDOM.render(<PageWelcome />, document.getElementById('contentCon'));
        }else if(page === 200){
            ReactDOM.render(<PageAdmin />, document.getElementById('contentCon'));
        }else if(page === 300){
            ReactDOM.render(<MyProfile />, document.getElementById('contentCon'));
        }else if(page === 400){
            ReactDOM.render(<Orders />, document.getElementById('contentCon'));
        }else if(page === 500){
            ReactDOM.render(<Promotions />, document.getElementById('contentCon'));
        }else if(page === 600){
            ReactDOM.render(<Review />, document.getElementById('contentCon'));
        }else{
            let curPage = dropdowns.page.filter(drop => drop.prevalue == page);
            ReactDOM.render(<Page page={curPage[0]} />, document.getElementById('contentCon'));
        }
    }

    const changeLanguage = (lang) => {
        setDefaultLang(lang);

        SetCookie('kyka-language', lang);

        __init();
    }

    // const trans = async (text) => await Translate(text);

    return(
        <div className="main-container">
            <div className='d-flex flex-row justify-content-between topbar'>
                <img src={mojaveLogo} alt='Mojave Logo' />
                <div className='companyName'>Kyka {t("auth.mall")} {t("auth.cms")}</div>
                <small className="d-flex flex-row">
                    <select className="form-control form-control-sm mr-2" onChange={(e) => changeLanguage(e.target.value)}>
                        {dropdowns.language !== undefined && dropdowns.language.map(lang =>
                        <option value={lang.prevalue} selected={lang.prevalue === defaultLang}>{lang.prename}</option>)}
                    </select>
                    <span style={{whiteSpace:'nowrap',lineHeight:'32px'}}>
                        <a href="#/" style={{color:'inherit'}} onClick={() => showPages(300)}>{user.firstName} {user.lastName}</a> | <a href="#" onClick={() => Logout()}>{t("auth.logout")}</a>
                        </span>
                </small>
            </div>
            <div className='d-flex flex-row justify-content-center mt-4 btnCon'>
                <button id={`menuBtn_100`} onClick={() => showPages(100)} className={`btn btn-sm active`}>{t("welcome.welcome")}</button>

                {dropdowns.page !== undefined && dropdowns.page.map(drop => drop.prevalue == 3 && 
                <button id={`menuBtn_${drop.prevalue}`} onClick={() => showPages(drop.prevalue)} className={`btn btn-sm`}>{t("welcome.products")}</button>)}

                <button id={`menuBtn_400`} onClick={() => showPages(400)} className={`btn btn-sm`}>{t("welcome.orders")}</button>

                <button id={`menuBtn_500`} onClick={() => showPages(500)} className={`btn btn-sm`}>{t("welcome.promotion")}</button>

                <button id={`menuBtn_600`} onClick={() => showPages(600)} className={`btn btn-sm`}>{t("welcome.review")}</button>

                <button id={`menuBtn_200`} onClick={() => showPages(200)} className={`btn btn-sm`}>{t("welcome.preference")}</button>
            </div>
            <div className='buttom-line'></div>
            <div className="" id="contentCon"></div>
        </div>
    )
}

export default DefaultLayout;