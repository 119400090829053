import React, { useState } from 'react';
import { GetJCookie, TableBase } from '../../utils/MojaveLib';
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import OrderList from './OrderList';

function Orders(){

    const [status, setStatus] = useState(1);

    const {t, i18n} = useTranslation('translation');

    const dropdowns = GetJCookie('kyka-prepo');

    const handleChange = (e) => {
        e.preventDefault();

        const {name, value} = e.target;

        setStatus(value);

        showList(value);
    }

    const showList = (status) => ReactDOM.render(<OrderList status={status} />, document.getElementById('orderCon'));

    return(
        <div className="row p-4">
            <div className="col p-4">
                <div className="adminHead d-flex flex-row justify-content-between">
                    <span>{t('welcome.orders')}</span>
                    <div className="d-flex flex-row">
                        <select className="form-control form-control-sm" id="status" name="status" 
                            value={status} onChange={handleChange}>
                            {dropdowns.order_status !== undefined && dropdowns.order_status.map(cat => 
                                <option value={cat.prevalue}>{cat.prename}</option>)}
                        </select>
                    </div>
                </div>
                <div className="orderBody" id='orderCon'><OrderList status={status} /></div>
            </div>
        </div>
    )
}

export default Orders;