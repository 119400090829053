import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom';
import { DConnect, DecryptData, EncryptData, GetJCookie, GetUser, HandleError, PostOptions, TableBase, showAlert } from "../../utils/MojaveLib";
import axios from "axios";
import moment from 'moment';
import UserForm from "./UserForm";
import Dto from "../../utils/serializers";
import UserRole from "./UserRole";

function Users(props){

    const [title, setTitle] = useState(props.title);
    const [subTitle, setSubTitle] = useState(props.subTitle);
    const [roleId, setRoleId] = useState(props.roleId);
    const [userItems, setUserItems] = useState(Dto.users);
    const [roleUsers, setRoleUsers] = useState([]);
    const [users, setUsers] = useState(props.users);
    const [smShow, setSmShow] = useState(false);

    const {t, i18n} = useTranslation('translation');

    const dropdowns = GetJCookie('kyka-prepo');

    const columns = [
        {name: t("setup.name"), selector: row => formatName(row.firstName, row), sortable: true},
        {name: t("auth.email"), selector: row => row.email, sortable: true},
        {name: t("auth.mobile"), selector: row => row.mobile, sortable: true},
        {name: t("coupon.verified"), selector: row => formatVerify(row.mobile_verified, row), sortable: true},
        {name: t("setup.signupdate"), selector: row => moment(row.created_on).format('DD MMM, YYYY'), sortable: true},
        {name: t("setup.lastmodify"), selector: row => formatUser(row.modified_by, row), sortable: true},
        {name: '', selector: row => formatAction(row.id, row), sortable: true},
    ];

    useEffect(() => {
        setUsers(props.users);
        setTitle(props.title);
        setSubTitle(props.subTitle);
        setRoleId(props.roleId);

        getInitData(props.roleId);
    },[i18n, props.users, props.title, props.roleId, props.subTitle]);

    const getInitData = (roleId) => {
        if(roleId === undefined || roleId <= 0){
            return;
        }
        
        DConnect().get(`user/?roleId=${roleId}`).then(res => {
            let users = DecryptData(res.data);

            if(users.length > 0){
                setRoleUsers(users);
            }
        }).catch(error =>HandleError(error));
    }

    const showDialog = (userItems) => {
        setUserItems(userItems);

        setSmShow(true);
    }

    const smClose = () => setSmShow(false);

    const saveUser = (formData) => {
        let url =  `user`, protocol = 'POST';

        if(formData.id > 0){
            url = `user/${formData.id}`;
            protocol = 'PUT';
            formData.modifiedBy = GetUser().id;
        }else{
            formData.roleId = roleId;
            formData.createdBy = GetUser().id;
        }

        axios(PostOptions(protocol,{'data':EncryptData(JSON.stringify(formData))}, url)).then(res => {
            let resdata = res.data;

            getInitData(roleId);

            showAlert({ title: t("setup.user"), message: `${t("setup.user")} ${t("setup.saved_success")}...`, btnText: t("setup.ok") });
        }).catch(error => { 
            showAlert({ title: t("setup.user"), message: `${t("setup.saved_error")} ${t("setup.user")}...${error.message}`, btnText: t("setup.ok") });
        });
    }

    const formatAction = (cell, row) => <div style={{fontSize:'20px', lineHeight:'20px'}}>
        <i className='material-icons text-success' role="button" title={t("setup.edit")}  onClick={() => showDialog(row)}>edit</i> &nbsp; | &nbsp; 
        <i className='material-icons text-danger' role="button" title={t("setup.delete")}>delete</i>
    </div>;

    const formatVerify = (cell, row) => {
        return cell;
    }

    const formatName = (cell, row) => `${cell} ${row.lastName}`;

    const formatUser = (cell, row) => {
        let username = users.filter(user => user.id === cell);

        return (username.length > 0) ? `${username[0].firstName} ${username[0].lastName}` : '-';
    }

    const goBack = () => ReactDOM.render(<UserRole title={title} />, document.getElementById("adminCon"));

    return(
        <div>
            <div className="adminHead d-flex flex-row justify-content-between">
                <span>{subTitle} {t("setup.cities")}</span>
                <div className="d-flex flex-row">
                    <button className="btn btn-sm btn-warning ml-2" onClick={goBack}>{t("setup.back")}</button>
                    <button className="btn btn-sm ml-2" onClick={() => showDialog({...Dto.cities})}>{t("setup.new")} {t("setup.user")}</button>
                </div>
            </div>
            <div className="adminBody">
                <TableBase keys="id" data={roleUsers} columns={columns} striped dense hover responsive />
            </div>

            <UserForm show={smShow} onHide={smClose} saveUser={saveUser} userItems={userItems} />
        </div>
    );
}

export default Users;