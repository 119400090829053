import React, { useEffect, useState } from 'react';
import mojaveLogo from '../../utils/images/mojave_logo_white.png';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import Dto from '../../utils/serializers';
import { DConnect, DoLogin, EncryptData, GetCookie, GetJCookie, HandleError, SetCookie } from '../../utils/MojaveLib';
import moment from 'moment';
import _ from 'lodash';

function Login(){

    const [dropdowns, setDropdowns] = useState({});
    const [loginItems, setLoginItems] = useState(Dto.login);
    const [defaultLang, setDefaultLang] = useState(GetCookie('kyka-language'));

    const {t, i18n} = useTranslation('translation');

    useEffect(() => {

        if(GetCookie('kyka-language') === null){
            SetCookie('kyka-language', 'en');
            i18n.changeLanguage('en');
        }

        getDropdowns();
    },[i18n, GetCookie('kyka-language')]);

    const getDropdowns = () => {
        DConnect().get(`prepo`).then(res => {
            if(res.data.length > 0){
                setDropdowns(_.groupBy(res.data, 'pretype'));
            }
        }).catch(error =>HandleError(error));
    }

    const handleChange = (e) => {
        e.preventDefault();

        const {name, value} = e.target;

        setLoginItems(prevProps => ({...prevProps, [name]:value}));
    }

    const changeLanguage = (lang) => {
        setDefaultLang(lang);

        SetCookie('kyka-language', lang);

        i18n.changeLanguage(lang);
    }

    const doLogin = (e) => {
        e.preventDefault();

        let formData = {...loginItems};
            formData.email = loginItems.email.trim();

        DoLogin({'data':EncryptData(JSON.stringify(formData))});
    }

    return(
        <div className="main-container">
            <div className='d-flex flex-row justify-content-between topbar'>
                <img src={mojaveLogo} alt='Mojave Logo' />
                <div className='companyName'>Kyka {t("auth.wholesale")} {t("auth.cms")}</div>
                <small className="d-flex flex-row">
                    <select className="form-control form-control-sm mr-2" onChange={(e) => changeLanguage(e.target.value)}>
                        {dropdowns.language !== undefined && dropdowns.language.map(lang =>
                        <option value={lang.prevalue} selected={lang.prevalue === defaultLang}>{lang.prename}</option>)}
                    </select>
                    <span style={{whiteSpace:'nowrap',lineHeight:'32px'}}>{t("auth.powered_by")} <a href='http://mojaveconsult.com' target='_blank'>Mojave</a></span>
                </small>
            </div>
            <div className='d-flex flex-row justify-content-center mt-4 btnCon'>
                <button className='btn btn-sm active'>{t("auth.authorization")}</button>
            </div>
            <div className='buttom-line'></div>
            <div className='d-flex justify-content-center align-items-center mt-4 loginCon'>
                <div className="login">
                    <div className='title mb-3'>{t("auth.user_login")}</div>
                    
                    <div className='controls'>
                        <form id='frmLogin' onSubmit={doLogin}>
                            <div className='form-group'>
                                <label for="">{t("auth.email")}: </label>
                                <input type='email' className='form-control form-control-sm' name='email' id='email'
                                    value={loginItems.email} onChange={handleChange} />
                            </div>
                            <div className='form-group'>
                                <label for="">{t("auth.password")}: </label>
                                <input type='password' className='form-control form-control-sm' name='password' id='password'
                                    value={loginItems.password} onChange={handleChange} />
                            </div>
                        </form>
                    </div>
                    <div className='d-flex justify-content-center mb-4'>
                        <button type='submit' form='frmLogin' className='btn btn-sm btnLogin'>{t("auth.login")}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;